// import React, { useEffect, useState } from 'react';
// import './TradeBlotter.css';
// import { Form, Row, Col, Card, Table, Button, Dropdown, DropdownButton, Modal, Container } from 'react-bootstrap';
// import Header from '../Header/Header';
// import Modallogo from "../../Assets/modallogo.png"
// import { useLocation } from 'react-router-dom';

// const initialData = [
//     { month: 'May', currentPosition: 156000, may: 390000, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: 390000, bid: '', ask: 3900, status: 'Success' },
//     { month: 'Jun', currentPosition: 312000, may: null, jun: 195000, jul: 429000, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: 624000, bid: '', ask: 6240, status: 'Success' },
//     { month: 'Jul', currentPosition: 312000, may: null, jun: null, jul: 163800, aug: 312000, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: 475800, bid: '', ask: 4758, status: 'Declined' },
//     { month: 'Aug', currentPosition: 241800, may: null, jun: null, jul: null, aug: 163800, sep: 312000, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: 475800, bid: '', ask: 4758, status: 'Pending' },
//     { month: 'Sep', currentPosition: 265200, may: null, jun: null, jul: null, aug: null, sep: 85800, oct: 163800, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 2496, status: 'Success' },
//     { month: 'Oct', currentPosition: 171600, may: null, jun: null, jul: null, aug: null, sep: null, oct: 85800, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: 85800, bid: '', ask: 858, status: 'Success' },
//     { month: 'Nov', currentPosition: 93600, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: 257400, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: 257400, bid: '', ask: 2574, status: 'Pending' },
//     { month: 'Dec', currentPosition: 195000, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: 85800, jan: 296400, feb: null, mar: null, apr: null, proposedTrade: 382200, bid: '', ask: 3822, status: 'Declined' },
//     { month: 'Jan', currentPosition: 522600, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: 343200, feb: null, mar: null, apr: null, proposedTrade: 343200, bid: '', ask: 3432, status: 'Pending' },
//     { month: 'Feb', currentPosition: 351000, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: '', status: '' },
//     { month: 'Mar', currentPosition: 265200, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: 312000, apr: null, proposedTrade: 585000, bid: '', ask: 5850, status: 'Declined' },
//     { month: 'Apr', currentPosition: 218400, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: 429000, proposedTrade: 1294800, bid: '', ask: 12948, status: 'Pending' },
// ];



// const TradeBlotter = ({route}) => {
//     const [data, setData] = useState(initialData);
//     // State variables for modal
//     const [showModal, setShowModal] = useState(false);
//     // Function to handle modal opening
//     const handleShowModal = () => setShowModal(true);
//     // Function to handle modal closing
//     const handleCloseModal = () => setShowModal(false);

//     const location = useLocation();
// const { optimalHedges } = location.state || { optimalHedges: [] };

//     useEffect(()=>{
//         console.log("YYTYTYTYTY",optimalHedges)
//     })


//     const [buttonStates, setButtonStates] = useState(initialData.map(() => ({
//         atBidEnabled: false,
//         atMarketEnabled: true,
//         atBidColor: '#E2E1E5',
//         atMarketColor: '#0FC4B8',
//         atBidText: 'At Bid',
//         atMarketText: 'At Market'
//     })));


//     const handleBidChange = (index, event) => {
//         const newValue = event.target.value;
//         const newData = [...data];
//         newData[index].bid = newValue;
//         setData(newData);

//         const newButtonStates = [...buttonStates];
//         if (newValue) {
//             newButtonStates[index] = {
//                 atBidEnabled: true,
//                 atMarketEnabled: false,
//                 atBidColor: '#16B0C2',
//                 atMarketColor: '#E2E1E5',
//                 atBidText: 'At Bid',
//                 atMarketText: 'At Market'
//             };
//         } else {
//             newButtonStates[index] = {
//                 atBidEnabled: false,
//                 atMarketEnabled: true,
//                 atBidColor: '#E2E1E5',
//                 atMarketColor: '#0FC4B8',
//                 atBidText: 'At Bid',
//                 atMarketText: 'At Market'
//             };
//         }
//         setButtonStates(newButtonStates);
//     };

//     const handleExecuteAll = () => {
//         const newButtonStates = buttonStates.map(state => ({
//             atBidEnabled: false,
//             atMarketEnabled: false,
//             atBidColor: state.atBidEnabled ? '#28a745' : state.atBidColor,
//             atMarketColor: state.atMarketEnabled ? '#28a745' : state.atMarketColor,
//             atBidText: state.atBidEnabled ? 'Success' : state.atBidText,
//             atMarketText: state.atMarketEnabled ? 'Success' : state.atMarketText,

//         }));
//         setButtonStates(newButtonStates);
//         handleCloseModal();
//     };

//     const calculateTotalProposedHedge = () => {
//         const totals = {
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0,
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//         };

//         data.forEach(row => {
//             Object.keys(totals).forEach(month => {
//                 if (row[month]) {
//                     totals[month] += row[month];
//                 }
//             });
//         });

//         return totals;
//     };

//     const totals = calculateTotalProposedHedge();

//     return (
//         <>
//             <Header />

//             <div className='mt-3' style={{ color: '#05004E', fontSize: '25px', fontWeight: '600', marginLeft: '130px' }}>Dynamic Hedging</div>
//             <Container fluid>
//                 <div className="main-wrapper">
//                     <div className="vertical-text ms-3" style={{ color: '#05004E', fontSize: '25px', fontWeight: '600', }}>Forward Contract</div>
//                     <Card className="border-1 border border-light-subtle shadow mt-4 " style={{ borderRadius: '10px', marginLeft: '50px', position: 'relative', backgroundColor: '#d5dee5' }}>
//                         <div className="table-responsive">
//                             <table>
//                                 <thead>
//                                     <tr>
//                                         <th colSpan="14" className="exposure-month col-lg-9 text-center" style={{ color: '#0F3659', backgroundColor: '#d5dee5', padding: '15px', borderRight: '1px solid #c9c6c6' }}>Exposure Month</th>
//                                         <th colSpan="6" className="proposed-trade text-center" style={{ color: '#0F3659', backgroundColor: '#d5dee5', padding: '15px' }}>Proposed Trade</th>
//                                     </tr>
//                                     <tr>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Month</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Current Position</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>May</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Jun</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Jul</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Aug</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Sep</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Oct</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Nov</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Dec</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Jan</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Feb</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Mar</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Apr</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', borderLeft: '1px solid #ddd' }}>Proposed Trade</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Bid</th>
//                                         <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Ask</th>
//                                         {/* <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}></th> */}
//                                         <th colSpan={2} style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>
//                                             <Button size="sm" className='btn ms-3' style={{ color: 'white', backgroundColor: '#0FC4B8', width: '150px', borderColor: '#0FC4B8' }} onClick={handleShowModal}>Execute All</Button>
//                                         </th>
//                                         {/* Modal component */}
//                                         <Modal show={showModal} onHide={handleCloseModal} centered aria-labelledby="contained-modal-title-vcenter" >
//                                             <div className='d-flex justify-content-center mt-3'>
//                                                 <img src={Modallogo} alt="icon" style={{ width: '50%' }} />
//                                             </div>
//                                             <Modal.Body style={{ fontSize: '24px', justifyContent: 'center', fontWeight: '400', }}>
//                                                 <div className='d-flex justify-content-center mt-3 text-center'>
//                                                     Are you sure you want to execute all trades?
//                                                 </div>
//                                             </Modal.Body>
//                                             <Modal.Footer>
//                                                 <Button variant="secondary" onClick={handleCloseModal}>
//                                                     Cancel
//                                                 </Button>
//                                                 <Button onClick={handleExecuteAll} style={{ background: "linear-gradient(240deg, #00B7C5 20%, #0072C5 100%)", borderColor: '#0072C5', }}>
//                                                     Execute
//                                                 </Button>
//                                             </Modal.Footer>
//                                         </Modal>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {data.map((row, index) => (
//                                         <tr key={index}>
//                                             <td style={{ padding: '19px', fontWeight: '400', border: '1px solid #ddd' }}>{row.month}</td>
//                                             <td style={{ color: '#02A6D9', border: '1px solid #ddd', fontWeight: '500' }}>{row.currentPosition.toLocaleString()}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.may ? row.may.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.jun ? row.jun.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.jul ? row.jul.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.aug ? row.aug.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.sep ? row.sep.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.oct ? row.oct.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.nov ? row.nov.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.dec ? row.dec.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.jan ? row.jan.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.feb ? row.feb.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.mar ? row.mar.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>{row.apr ? row.apr.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd', color: '#02A6D9' }}>{row.proposedTrade ? row.proposedTrade.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>
//                                                 <input
//                                                     style={{ width: '100px', height: '30px', border: '1px solid #737791', padding: '5px', borderRadius: '6px', textAlign: 'center' }}
//                                                     type="text"
//                                                     value={row.bid}
//                                                     onChange={(event) => handleBidChange(index, event)}
//                                                     placeholder="Enter Price"
//                                                 />

//                                             </td>
//                                             <td style={{ border: '1px solid #ddd', color: '#02A6D9' }}>{row.ask ? row.ask.toLocaleString() : '--'}</td>
//                                             <td style={{ border: '1px solid #ddd' }}>

//                                                 <button
//                                                     className='btn'
//                                                     // style={{ color: buttonStates[index].atBidEnabled ? 'white' : '#737791', backgroundColor: buttonStates[index].atBidColor, width: '100px', height: '40px' }}
//                                                     // disabled={!buttonStates[index].atBidEnabled}
//                                                     style={{ color: buttonStates[index].atBidEnabled ? 'white' : '#000', backgroundColor: buttonStates[index].atBidColor, borderColor: buttonStates[index].atBidColor, width: '100px', height: '40px' }}
//                                                     disabled={!buttonStates[index].atBidEnabled}
//                                                 >
//                                                     {buttonStates[index].atBidText}
//                                                 </button>
//                                             </td>
//                                             <td style={{ border: '1px solid #ddd' }}>
//                                                 <button
//                                                     className='btn'
//                                                     // style={{ color: buttonStates[index].atMarketEnabled ? 'white' : '#000', backgroundColor: buttonStates[index].atMarketColor, width: '100px', height: '40px' }}
//                                                     // disabled={!buttonStates[index].atMarketEnabled}
//                                                     style={{ color: buttonStates[index].atMarketEnabled ? 'white' : '#000', backgroundColor: buttonStates[index].atMarketColor, borderColor: buttonStates[index].atMarketColor, width: '100px', height: '40px' }}
//                                                     disabled={!buttonStates[index].atMarketEnabled}
//                                                 >
//                                                     {buttonStates[index].atMarketText}
//                                                 </button>
//                                             </td>
//                                         </tr>
//                                     ))}
//                                     {/* <tr>
//                                     <td colSpan="14" className="text-center" style={{ padding: '10px', border: 'none', backgroundColor: '#d5dee5' }}></td>
//                                     <td style={{ fontWeight: '600', border: '1px solid #ddd' }}>{totals.totalProposedTrade}</td>
//                                     <td colSpan="4" style={{ fontWeight: '600', border: '1px solid #ddd', color: '#02A6D9' }}>{totals.totalProposedHedge}</td>
//                                 </tr> */}
//                                     <tr style={{ color: '#fff', padding: '19px', backgroundColor: '#0F3659', paddingBottom: '10px' }}>
//                                         <td colSpan="2" style={{ fontWeight: 'bold', padding: '19px', backgroundColor: '#0F3659' }}>Total Proposed Hedge</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.may.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.jun.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.jul.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.aug.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.sep.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.oct.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.nov.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.dec.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.jan.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.feb.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.mar.toLocaleString()}</td>
//                                         <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.apr.toLocaleString()}</td>
//                                         <td colSpan="5" className="exposure-month  text-center" style={{ backgroundColor: '#0F3659', padding: '15px', borderRight: '1px solid #c9c6c6' }}>
//                                             <Button style={{ color: 'white', backgroundColor: '#0FC4B8', width: '150px', borderColor: '#0FC4B8' }}>Exposure Month</Button></td>
//                                     </tr>

//                                 </tbody>
//                             </table>
//                         </div>
//                     </Card>
//                 </div>
//             </Container>
//         </>
//     );
// };

// export default TradeBlotter;


import React, { useEffect, useState } from 'react';
import './TradeBlotter.css';
import { Form, Row, Col, Card, Table, Button, Dropdown, DropdownButton, Modal, Container } from 'react-bootstrap';
import Header from '../Header/Header';
import Modallogo from "../../Assets/modallogo.png"
import { useLocation } from 'react-router-dom';

const initialData = [
    { month: 'May', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 3900, status: 'Success' },
    { month: 'Jun', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 6240, status: 'Success' },
    { month: 'Jul', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 4758, status: 'Declined' },
    { month: 'Aug', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 4758, status: 'Pending' },
    { month: 'Sep', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 2496, status: 'Success' },
    { month: 'Oct', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 858, status: 'Success' },
    { month: 'Nov', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 2574, status: 'Pending' },
    { month: 'Dec', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 3822, status: 'Declined' },
    { month: 'Jan', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 3432, status: 'Pending' },
    { month: 'Feb', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: '', status: '' },
    { month: 'Mar', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 5850, status: 'Declined' },
    { month: 'Apr', currentPosition: 0, may: null, jun: null, jul: null, aug: null, sep: null, oct: null, nov: null, dec: null, jan: null, feb: null, mar: null, apr: null, proposedTrade: null, bid: '', ask: 12948, status: 'Pending' },
];



const TradeBlotter = ({ route }) => {
    const [data, setData] = useState(initialData);
    // State variables for modal
    const [showModal, setShowModal] = useState(false);
    // Function to handle modal opening
    const handleShowModal = () => setShowModal(true);
    // Function to handle modal closing
    const handleCloseModal = () => setShowModal(false);

    const location = useLocation();
    const { currentHedges } = location.state || { currentHedges: [] };

    useEffect(() => {
        if (currentHedges.length > 0) {
            setData(prevData => prevData.map((item, index) => ({
                ...item,
                currentPosition: currentHedges[index] || item.currentPosition
            })));
        }
    }, [currentHedges]);

    const { currentUnhedges } = location.state || { currentUnhedges: [] };

    useEffect(() => {
        if (currentUnhedges.length > 0) {
            setData(prevData => prevData.map((item, index) => ({
                ...item,
                proposedTrade: currentUnhedges[index] || item.proposedTrade
            })));
        }
    }, [currentUnhedges]);

    const { months } = location.state || { months: [] };
    useEffect(() => {
        if (months.length > 0) {
            setData(prevData => prevData.map((item, index) => ({
                ...item,
                month: months[index] || item.month
            })));
        }
    }, [months]);

    const [buttonStates, setButtonStates] = useState(initialData.map(() => ({
        atBidEnabled: false,
        atMarketEnabled: true,
        atBidColor: '#E2E1E5',
        atMarketColor: '#0FC4B8',
        atBidText: 'At Bid',
        atMarketText: 'At Market'
    })));


    const handleBidChange = (index, event) => {
        const newValue = event.target.value;
        const newData = [...data];
        newData[index].bid = newValue;
        setData(newData);

        const newButtonStates = [...buttonStates];
        if (newValue) {
            newButtonStates[index] = {
                atBidEnabled: true,
                atMarketEnabled: false,
                atBidColor: '#16B0C2',
                atMarketColor: '#E2E1E5',
                atBidText: 'At Bid',
                atMarketText: 'At Market'
            };
        } else {
            newButtonStates[index] = {
                atBidEnabled: false,
                atMarketEnabled: true,
                atBidColor: '#E2E1E5',
                atMarketColor: '#0FC4B8',
                atBidText: 'At Bid',
                atMarketText: 'At Market'
            };
        }
        setButtonStates(newButtonStates);
    };

    const handleExecuteAll = () => {
        const newButtonStates = buttonStates.map(state => ({
            atBidEnabled: false,
            atMarketEnabled: false,
            atBidColor: state.atBidEnabled ? '#28a745' : state.atBidColor,
            atMarketColor: state.atMarketEnabled ? '#28a745' : state.atMarketColor,
            atBidText: state.atBidEnabled ? 'Success' : state.atBidText,
            atMarketText: state.atMarketEnabled ? 'Success' : state.atMarketText,

        }));
        setButtonStates(newButtonStates);
        handleCloseModal();
    };

    const calculateTotalProposedHedge = () => {
        const totals = {
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
        };

        data.forEach(row => {
            Object.keys(totals).forEach(month => {
                if (row[month]) {
                    totals[month] += row[month];
                }
            });
        });

        return totals;
    };

    const totals = calculateTotalProposedHedge();
       // Update the data to split proposed trade values for each month
       useEffect(() => {
        setData(prevData => prevData.map((item, index) => {
            let proposedTradeValue = item.proposedTrade;
            if (proposedTradeValue < 0) {
                proposedTradeValue = 0;
            }
            if (proposedTradeValue) {
                const splitValue60 = (proposedTradeValue * 0.60 ).toFixed(2);
                const splitValue40 = (proposedTradeValue * 0.40).toFixed(2);
                
                if (item.month === 'May') {
                    return {
                        ...item,
                        may: `${splitValue60}`,
                        jun: `${splitValue40}`,
                    };
                } else if (item.month === 'Jun') {
                    return {
                        ...item,
                        jun: `${splitValue60}`,
                        jul: `${splitValue40}`,
                    };
                } else if (item.month === 'Jul') {
                    return {
                        ...item,
                        jul: `${splitValue60}`,
                        aug: `${splitValue40}`,
                    };
                } else if (item.month === 'Aug') {
                    return {
                        ...item,
                        aug: proposedTradeValue >= 0 ? `${splitValue60}` : '0',
                        sep: '0',
                    };
                } else if (item.month === 'Sep') {
                    return {
                        ...item,
                        sep: `${splitValue60}`,
                        oct: `${splitValue40}`,
                    };
                } else if (item.month === 'Oct') {
                    return {
                        ...item,
                        oct: `${splitValue60}`,
                        nov: `${splitValue40}`,
                    };
                } else if (item.month === 'Nov') {
                    return {
                        ...item,
                        nov: `${splitValue60}`,
                        dec: `${splitValue40}`,
                    };
                } else if (item.month === 'Dec') {
                    return {
                        ...item,
                        dec: `${splitValue60}`,
                        jan: `${splitValue40}`,
                    };
                } else if (item.month === 'Jan') {
                    return {
                        ...item,
                        jan: `${splitValue60}`,
                        feb: `${splitValue40}`,
                    };
                } else if (item.month === 'Feb') {
                    return {
                        ...item,
                        feb: `${splitValue60}`,
                        mar: `${splitValue40}`,
                    };
                } else if (item.month === 'Mar') {
                    return {
                        ...item,
                        mar: `${splitValue60}`,
                        apr: `${splitValue40}`,
                    };
                }
            }
            return item;
        }));
    }, []);

    return (
        <>
            <Header />

            <div className='mt-3' style={{ color: '#05004E', fontSize: '25px', fontWeight: '600', marginLeft: '130px' }}>Dynamic Hedging</div>
            <Container fluid>
                <div className="main-wrapper">
                    <div className="vertical-text ms-3" style={{ color: '#05004E', fontSize: '25px', fontWeight: '600', }}>Forward Contract</div>
                    <Card className="border-1 border border-light-subtle shadow mt-4 " style={{ borderRadius: '10px', marginLeft: '50px', position: 'relative', backgroundColor: '#d5dee5' }}>
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan="14" className="exposure-month col-lg-9 text-center" style={{ color: '#0F3659', backgroundColor: '#d5dee5', padding: '15px', borderRight: '1px solid #c9c6c6' }}>Exposure Month</th>
                                        <th colSpan="6" className="proposed-trade text-center" style={{ color: '#0F3659', backgroundColor: '#d5dee5', padding: '15px' }}>Proposed Trade</th>
                                    </tr>
                                    <tr>
                                        <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Month</th>
                                        <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Current Position</th>
                                        {data.map((row, index) => (
                                            <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>{row.month}</th>
                                        ))}
                                        <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', borderLeft: '1px solid #ddd' }}>Proposed Trade</th>
                                        <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Bid</th>
                                        <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>Ask</th>
                                        {/* <th style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}></th> */}
                                        <th colSpan={2} style={{ color: '#02A6D9', padding: '19px ', backgroundColor: '#fff', border: 'none' }}>
                                            <Button size="sm" className='btn ms-3' style={{ color: 'white', backgroundColor: '#0FC4B8', width: '150px', borderColor: '#0FC4B8' }} onClick={handleShowModal}>Execute All</Button>
                                        </th>
                                        {/* Modal component */}
                                        <Modal show={showModal} onHide={handleCloseModal} centered aria-labelledby="contained-modal-title-vcenter" >
                                            <div className='d-flex justify-content-center mt-3'>
                                                <img src={Modallogo} alt="icon" style={{ width: '50%' }} />
                                            </div>
                                            <Modal.Body style={{ fontSize: '24px', justifyContent: 'center', fontWeight: '400', }}>
                                                <div className='d-flex justify-content-center mt-3 text-center'>
                                                    Are you sure you want to execute all trades?
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCloseModal}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleExecuteAll} style={{ background: "linear-gradient(240deg, #00B7C5 20%, #0072C5 100%)", borderColor: '#0072C5', }}>
                                                    Execute
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: '19px', fontWeight: '400', border: '1px solid #ddd' }}>{row.month}</td>
                                            <td style={{ color: '#02A6D9', border: '1px solid #ddd', fontWeight: '500' }}>{row.currentPosition.toLocaleString()}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.may ? row.may.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.jun ? row.jun.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.jul ? row.jul.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.aug ? row.aug.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.sep ? row.sep.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.oct ? row.oct.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.nov ? row.nov.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.dec ? row.dec.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.jan ? row.jan.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.feb ? row.feb.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.mar ? row.mar.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>{row.apr ? row.apr.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd', color: '#02A6D9' }}>{row.proposedTrade ? row.proposedTrade.toLocaleString() : '--'}K</td>

                                            <td style={{ border: '1px solid #ddd' }}>
                                                <input
                                                    style={{ width: '100px', height: '30px', border: '1px solid #737791', padding: '5px', borderRadius: '6px', textAlign: 'center' }}
                                                    type="text"
                                                    value={row.bid}
                                                    onChange={(event) => handleBidChange(index, event)}
                                                    placeholder="Enter Price"
                                                />

                                            </td>
                                            <td style={{ border: '1px solid #ddd', color: '#02A6D9' }}>{row.ask ? row.ask.toLocaleString() : '--'}</td>
                                            <td style={{ border: '1px solid #ddd' }}>

                                                <button
                                                    className='btn'
                                                    // style={{ color: buttonStates[index].atBidEnabled ? 'white' : '#737791', backgroundColor: buttonStates[index].atBidColor, width: '100px', height: '40px' }}
                                                    // disabled={!buttonStates[index].atBidEnabled}
                                                    style={{ color: buttonStates[index].atBidEnabled ? 'white' : '#000', backgroundColor: buttonStates[index].atBidColor, borderColor: buttonStates[index].atBidColor, width: '100px', height: '40px' }}
                                                    disabled={!buttonStates[index].atBidEnabled}
                                                >
                                                    {buttonStates[index].atBidText}
                                                </button>
                                            </td>
                                            <td style={{ border: '1px solid #ddd' }}>
                                                <button
                                                    className='btn'
                                                    // style={{ color: buttonStates[index].atMarketEnabled ? 'white' : '#000', backgroundColor: buttonStates[index].atMarketColor, width: '100px', height: '40px' }}
                                                    // disabled={!buttonStates[index].atMarketEnabled}
                                                    style={{ color: buttonStates[index].atMarketEnabled ? 'white' : '#000', backgroundColor: buttonStates[index].atMarketColor, borderColor: buttonStates[index].atMarketColor, width: '100px', height: '40px' }}
                                                    disabled={!buttonStates[index].atMarketEnabled}
                                                >
                                                    {buttonStates[index].atMarketText}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {/* <tr>
                                    <td colSpan="14" className="text-center" style={{ padding: '10px', border: 'none', backgroundColor: '#d5dee5' }}></td>
                                    <td style={{ fontWeight: '600', border: '1px solid #ddd' }}>{totals.totalProposedTrade}</td>
                                    <td colSpan="4" style={{ fontWeight: '600', border: '1px solid #ddd', color: '#02A6D9' }}>{totals.totalProposedHedge}</td>
                                </tr> */}
                                    <tr style={{ color: '#fff', padding: '19px', backgroundColor: '#0F3659', paddingBottom: '10px' }}>
                                        <td colSpan="2" style={{ fontWeight: 'bold', padding: '19px', backgroundColor: '#0F3659' }}>Total Proposed Hedge</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.may.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.jun.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.jul.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.aug.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.sep.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.oct.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.nov.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.dec.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.jan.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.feb.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.mar.toLocaleString()}</td>
                                        <td style={{ backgroundColor: '#0F3659', border: '1px solid #676565' }}>{totals.apr.toLocaleString()}</td>
                                        <td colSpan="5" className="exposure-month  text-center" style={{ backgroundColor: '#0F3659', padding: '15px', borderRight: '1px solid #c9c6c6' }}>
                                            <Button style={{ color: 'white', backgroundColor: '#0FC4B8', width: '150px', borderColor: '#0FC4B8' }}>Exposure Month</Button></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>
            </Container>
        </>
    );
};

export default TradeBlotter;