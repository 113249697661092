import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ERPimg from "../../Assets/ERP.png";

const Beyond = () => {
  return (
    <>
      <div>
        <div className="container-fluid d-flex align-items-center mt-5">
          <Row className="d-flex justify-content-center align-items-center">
            <Col lg={5} sm={12} className="d-flex justify-content-center">
              <img src={ERPimg} alt="ERP" className="img-fluid"></img>
            </Col>
            <Col
              lg={5}
              sm={12}
              className="d-flex flex-column justify-content-center"
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#02A6D9",
                  textAlign: "end",
                }}
              >
                TMS, ERP, Fusion Treasury coexist
              </p>
              <p
                style={{
                  fontSize: "28px",
                  color: "#0F3659",
                  textAlign: "end",
                  lineHeight: "30px",
                  fontWeight: "500",
                }}
              >
                Fusion Treasury: Beyond TMS, Complementing ERP
              </p>
              <p
                style={{
                  fontSize: "26px",
                  color: "#02A6D9",
                  textAlign: "end",
                  fontWeight: "400",
                  lineHeight: "50px",
                }}
              >
                Fusion Treasury does not replace TMS or ERP systems but rather
                enhances them. It acts as a strategic adjunct that provides a
                more profound, analytical understanding, enabling treasury teams
                to make informed decisions with a future-ready approach.
              </p>
            </Col>
          </Row>
        </div>
        <div className="container mt-5 mb-5">
          <Row>
            <Col
              lg={4}
              md={12}
              sm={12}
              className="mt-3 d-flex justify-content-center"
            >
              <Card
                style={{
                  border: "none",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "300px",
                }}
              >
                <Card.Header
                  style={{
                    backgroundColor: "#2380C3",
                    color: "#ffffff",
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "28px",
                    borderTopLeftRadius: "14px",
                    borderTopRightRadius: "14px",
                    padding: "20px",
                  }}
                >
                  Fusion Treasury:
                  <br /> Strategic Enhancement for
                  <br /> Treasury Operations
                </Card.Header>
                <Card.Body className="text-center">
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Strategic Vision:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Elevates the financial function with
                    <br /> predictive modeling and foresight,
                    <br /> moving beyond the transaction-
                    <br />
                    focused nature of TMS.
                  </Card.Text>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Digital Twin Innovation:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Employs digital twin technology to
                    <br /> create a virtual, interactive model of
                    <br /> the treasury for in-depth analysis and
                    <br />
                    scenario testing.
                  </Card.Text>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    AI-Driven Proactivity:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Integrates AI to provide anticipatory <br />
                    insights, strategies, and risk <br />
                    assessments, shaping the future of
                    <br /> treasury management.
                  </Card.Text>
                </Card.Body>
                <Card.Footer
                  style={{
                    backgroundColor: "#2380C3",
                    borderBottomLeftRadius: "14px",
                    borderBottomRightRadius: "14px",
                    height: "30px",
                  }}
                ></Card.Footer>
              </Card>
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className="mt-3 d-flex justify-content-center"
            >
              <Card
                style={{
                  border: "none",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "300px",
                }}
              >
                <Card.Header
                  style={{
                    backgroundColor: "#00B7C5",
                    color: "#ffffff",
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "28px",
                    borderTopLeftRadius: "14px",
                    borderTopRightRadius: "14px",
                    padding: "33px",
                  }}
                >
                  Conventional Treasury
                  <br />
                  Management (TMS) & ERP
                </Card.Header>
                <Card.Body className="text-center">
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Operational Excellence:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    TMS focuses on managing transactions,
                    <br /> liquidity, and risks; ERP systems
                    <br /> manage broader business processes
                    <br /> and data.
                  </Card.Text>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Historical Data Reporting:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    TMS and ERP systems excel in recording
                    <br /> and reporting historical data, crucial for
                    <br /> compliance and operational reporting.
                  </Card.Text>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Financial Record Integrity:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Both systems ensure accuracy in <br />
                    financial records, serving as a <br />
                    foundation for financial operations and
                    <br /> strategy execution.
                  </Card.Text>
                </Card.Body>
                <Card.Footer
                  style={{
                    backgroundColor: "#00B7C5",
                    borderBottomLeftRadius: "14px",
                    borderBottomRightRadius: "14px",
                    height: "30px",
                  }}
                ></Card.Footer>
              </Card>
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className="mt-3 d-flex justify-content-center"
            >
              <Card
                style={{
                  border: "none",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  width: "300px",
                }}
              >
                <Card.Header
                  style={{
                    backgroundColor: "#09A2D1",
                    color: "#ffffff",
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "28px",
                    borderTopLeftRadius: "14px",
                    borderTopRightRadius: "14px",
                    padding: "20px",
                  }}
                >
                  Complementary
                  <br /> Dynamics – Coexist and add value
                </Card.Header>
                <Card.Body className="text-center">
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Operational & Strategic Synergy:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Fusion Treasury complements TMS &
                    <br /> ERP by adding a layer of simulation &
                    <br /> intelligence, transforming data into
                    <br /> forward-looking strategic insights.
                  </Card.Text>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    From Static to Adaptive:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    While TMS & ERP provide stability and
                    <br /> reliability, Fusion Treasury introduces
                    <br /> adaptive forecasting and strategic
                    <br /> modeling capabilities.
                  </Card.Text>
                  <Card.Title
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#02A6D9",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Augmented Decision-Making:
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#737791",
                      textAlign: "center",
                      lineHeight: "35px",
                    }}
                  >
                    Enhances the decision-making process, <br />
                    leveraging the robust data framework <br />
                    of TMS & ERP systems to provide an
                    <br /> expanded strategic perspective.
                  </Card.Text>
                </Card.Body>
                <Card.Footer
                  style={{
                    backgroundColor: "#09A2D1",
                    borderBottomLeftRadius: "14px",
                    borderBottomRightRadius: "14px",
                    height: "30px",
                  }}
                ></Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Beyond;
