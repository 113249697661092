// import React, { useState } from 'react';
// import { Col, Row } from 'react-bootstrap';
// import Autonomous from "../../Assets/autonomous.png"
// import Card from 'react-bootstrap/Card';
// const AutonomousAgent = () => {
//     const [selectedCard, setSelectedCard] = useState(null);

//     const handleCardClick = (cardName) => {
//         setSelectedCard(cardName);
//     };

//     const getCardStyle = (cardName) => ({
//         padding: '10px',
//         margin: '10px',
//         borderRadius: '10px',
//         borderColor: '#737791',
//         background: selectedCard === cardName
//             ? 'linear-gradient(180deg, #00B7C5 20%, #0072C5 100%)'
//             : '#FFFFFF',
//         color: selectedCard === cardName ? '#FFFFFF' : '#0F3659',
//     });

//     return (
//         <>
//             <div className='d-flex flex-column justify-content-center align-items-center pt-3 container-fluid' style={{ backgroundColor: '#F5F6FA' }}>
//                 <div className=" ">
//                     <div className='d-flex align-items-center ' style={{ minHeight: '50vh' }}>
//                         <Row className='d-flex justify-content-center align-items-center '>

//                             <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
//                                 <p style={{ fontSize: '20px', fontWeight: '500', color: '#02A6D9', textAlign: 'justify', }}>Autonomous digital workers</p>
//                                 <p style={{ fontSize: '30px', color: '#0F3659', textAlign: 'justify', lineHeight: '35px', fontWeight: '500', }}>Autonomous Agents</p>
//                                 <p style={{ fontSize: '26px', color: '#02A6D9', textAlign: 'justify', fontWeight: '400', }}>We provide goal oriented digital agents that enable your business expert  to find better ways to perform complex activities.</p>
//                                 <p style={{ fontSize: '22px', color: '#737791', textAlign: 'justify', lineHeight: '35px', fontWeight: '400', }}>The agents are not explicitly programmed and, unlike robots, they do not <br></br>simply copy human behaviours. Instead they leverage artificial intelligence and machine learning in order to find the optimal path to the business goals you set them, giving you the edge in business.</p>
//                             </Col>
//                             <Col lg={5} sm={12} className='d-flex justify-content-center'>
//                                 <img src={Autonomous} alt="cart" style={{ width: '65%' }}></img>
//                             </Col>
//                         </Row>
//                     </div>
//                     <div className='d-flex justify-content-center mt-3' style={{ backgroundColor: '#EEEEF3', minHeight: '70vh' }}>
//                         {selectedCard === 'digitalWork' && (
//                             <div className='d-flex align-items-center ' style={{ minHeight: '50vh' }}>
//                                 <Row className='d-flex justify-content-center align-items-center '>

//                                     <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
//                                         <p style={{ fontSize: '20px', fontWeight: '500', color: '#02A6D9', textAlign: 'justify', }}>Autonomous digital workers</p>
//                                         <p style={{ fontSize: '30px', color: '#0F3659', textAlign: 'justify', lineHeight: '35px', fontWeight: '500', }}>Autonomous Agents</p>
//                                         <p style={{ fontSize: '26px', color: '#02A6D9', textAlign: 'justify', fontWeight: '400', }}>We provide goal oriented digital agents that enable your business expert  to find better ways to perform complex activities.</p>
//                                         <p style={{ fontSize: '22px', color: '#737791', textAlign: 'justify', lineHeight: '35px', fontWeight: '400', }}>The agents are not explicitly programmed and, unlike robots, they do not <br></br>simply copy human behaviours. Instead they leverage artificial intelligence and machine learning in order to find the optimal path to the business goals you set them, giving you the edge in business.</p>
//                                     </Col>
//                                     <Col lg={5} sm={12} className='d-flex justify-content-center'>
//                                         <img src={Autonomous} alt="cart" style={{ width: '65%' }}></img>
//                                     </Col>
//                                 </Row>
//                             </div>
//                         )}
//                         {selectedCard === 'agentFramework' && (
//                             <div>
//                                 <div className='d-flex justify-content-center mt-3 col-lg-12'>

//                                 </div>
//                                 <div className='d-flex justify-content-center mt-5'>
//                                     <p style={{ fontSize: '19px', fontWeight: '400', color: '#737791' }} className="title text-center">We apply machine learning techniques to combine news items with market data and treasury positions to deliver deep insights<br></br>
//                                         and recommendations. Third party apps, market data providers, news sites, event listings are brought together into one place:<br></br>
//                                         a central Treasury feed based on your treasury digital twin.</p>
//                                 </div>
//                             </div>
//                         )}
//                         {selectedCard === 'Transactions' && (
//                             <div>
//                                 <h2>Transactions</h2>
//                                 <p>This is the content for Transactions.</p>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//             <div className='d-flex justify-content-center' style={{ backgroundColor: '#F5F6FA' }}>
//                 <Row className='col-10'>
//                     <Col lg={4} sm={12} className='mt-3'>
//                         <Card style={getCardStyle('digitalWork')} onClick={() => handleCardClick('Collaboration')}>
//                             <Card.Body className='text-center'>
//                                 <Card.Title style={{ fontSize: '25px', fontWeight: '500', textAlign: 'center' }}>
//                                     Autonomous digital workers
//                                 </Card.Title>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                     <Col lg={4} sm={12} className='mt-3'>
//                         <Card style={getCardStyle('agentFramework')} onClick={() => handleCardClick('Insights')}>
//                             <Card.Body className='text-center'>
//                                 <Card.Title style={{ fontSize: '25px', fontWeight: '500', textAlign: 'center' }}>
//                                     The Agent Framework
//                                 </Card.Title>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                     <Col lg={4} sm={12} className='mt-3'>
//                         <Card style={getCardStyle('Transactions')} onClick={() => handleCardClick('Transactions')}>
//                             <Card.Body className='text-center'>
//                                 <Card.Title style={{ fontSize: '25px', fontWeight: '500', textAlign: 'center' }}>
//                                     Core Concepts
//                                 </Card.Title>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 </Row>
//             </div>
//         </>
//     )
// }

// export default AutonomousAgent
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import agentframe from "../../Assets/agentframe.png"
import insight from "../../Assets/insight.png"
import Autonomous from "../../Assets/autonomous.png"
import coreconcept from "../../Assets/coreconcept.png"
import dynamic from "../../Assets/dynamic.png"
const AutonomousAgent = () => {
    const [selectedCard, setSelectedCard] = useState('digitalworker');

    const handleCardClick = (cardName) => {
        setSelectedCard(cardName);
    };

    const getCardStyle = (cardName) => ({
        padding: '10px',
        margin: '10px',
        borderRadius: '10px',
        borderColor: '#737791',
        background: selectedCard === cardName
            ? 'linear-gradient(180deg, #00B7C5 20%, #0072C5 100%)'
            : '#FFFFFF',
        color: selectedCard === cardName ? '#FFFFFF' : '#0F3659',
    });

    return (
        <>
            <div className='d-flex flex-column justify-content-center align-items-center pt-5 container-fluid ' style={{ backgroundColor: '#F5F6FA' ,minHeight: '60vh'}}>
                <div className="">
                    <div className='d-flex justify-content-center ' style={{ minHeight: '60vh' }}>
                        {selectedCard === 'digitalworker' && (
                            <div className='d-flex align-items-center col-12 mt-5' style={{}}>
                                <Row className='d-flex justify-content-center align-items-center '>
                                    <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                                        <p style={{ fontSize: '20px', fontWeight: '500', color: '#02A6D9', textAlign: 'justify', }}>Autonomous digital workers</p>
                                        <p style={{ fontSize: '30px', color: '#0F3659', textAlign: 'justify', lineHeight: '35px', fontWeight: '500', }}>Autonomous Agents</p>
                                        <p style={{ fontSize: '24px', color: '#02A6D9', textAlign: 'justify', fontWeight: '400', }}>We provide goal oriented digital agents that enable your business expert  to find better ways to perform complex activities.</p>
                                        <p style={{ fontSize: '20px', color: '#737791', textAlign: 'justify', lineHeight: '35px', fontWeight: '400', }}>The agents are not explicitly programmed and, unlike robots, they do not <br></br>simply copy human behaviours. Instead they leverage artificial intelligence and machine learning in order to find the optimal path to the business goals you set them, giving you the edge in business.</p>
                                    </Col>
                                    <Col lg={5} sm={12} className='d-flex justify-content-center'>
                                        <img src={Autonomous} alt="cart" className="img-fluid"></img>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        {selectedCard === 'agentframe' && (
                            <div>
                                <div className="col-lg-12">
                                    <p style={{ fontSize: '20px', fontWeight: '500', color: '#02A6D9' }} className="title text-center">The Agent Framework</p>
                                    <p className="mt-2 text-center" style={{ fontSize: '28px', color: '#0F3659', textAlign: 'justify', fontWeight: '500' }}>
                                        Introduction – The autonomous Treasury Digital Twin Framework
                                    </p>
                                </div>
                                <div className='d-flex justify-content-center mt-5 col-lg-12'>
                                    <img src={agentframe} alt="icon" className="img-fluid" />
                                </div>
                            </div>
                        )}
                        {selectedCard === 'Transactions' && (
                            <div className='d-flex justify-content-center'   >
                                <Row className='d-flex justify-content-center col-lg-12 '>
                                    <Col lg={6} sm={12} className='' >
                                        <img src={coreconcept} alt='core' className="img-fluid" style={{ width: '100%' ,height:'60vh'}} />
                                    </Col>
                                    <Col lg={6} sm={12} className=''>
                                        <img src={dynamic} alt='core' className="img-fluid" style={{ width: '100%',height:'60vh' }} />

                                    </Col>

                                </Row>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center pb-4' style={{ backgroundColor: '#F5F6FA' }}>
                <Row className='col-10'>
                    <Col lg={4} sm={12} className=''>
                        <Card style={getCardStyle('digitalworker')} onClick={() => handleCardClick('digitalworker')}>
                            <Card.Body className='text-center'>
                                <Card.Title style={{ fontSize: '25px', fontWeight: '500', textAlign: 'center' }}>
                                    Autonomous digital workers
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} sm={12} className=''>
                        <Card style={getCardStyle('agentframe')} onClick={() => handleCardClick('agentframe')}>
                            <Card.Body className='text-center'>
                                <Card.Title style={{ fontSize: '25px', fontWeight: '500', textAlign: 'center' }}>
                                    The Agent Framework
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} sm={12} className=''>
                        <Card style={getCardStyle('Transactions')} onClick={() => handleCardClick('Transactions')}>
                            <Card.Body className='text-center'>
                                <Card.Title style={{ fontSize: '25px', fontWeight: '500', textAlign: 'center' }}>
                                    Transactions
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

        </>
    );
}

export default AutonomousAgent;
