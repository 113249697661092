import React from "react";
const Loader = () => {
  return (
    <>
      <div className="spinner-container">
        <div
          id="cover-spin"
          className="loading-spinner d-flex m-auto loading"
        ></div>
      </div>
    </>
  );
};

export default Loader;
