import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import logo from "../../Assets/userlogin1.png";
import notification from "../../Assets/Notifications .png";
import setting from "../../Assets/setting.png";
import logoimg from "../../Assets/fulllogo.png";
import { BsGearFill } from "react-icons/bs";
import { MdOutlineHelp } from "react-icons/md";
const Header = () => {
  return (
    <Navbar expand="md" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand className="d-flex align-items-center">
          <Image
            src={logoimg}
            alt="Company Logo"
            className="ms-0 me-2"
            style={{color: "#05004E", cursor: "pointer", height: "30px" }}
          />
          {/* <span
            className="me-2"
            style={{ fontWeight: "600", fontSize: "24px" }}
          >
            <span
              className="me-2"
              style={{ color: "#1aa4c8", fontWeight: "700", fontSize: "25px" }}
            >
              Fusion
            </span>
            Treasury
          </span> */}
          {/* <span className="small" style={{ fontSize: "10px", color: "gray" }}>
            March 9 2024 14:22
          </span> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav" className="justify-content-end">
          <Navbar.Text className="d-flex align-items-center">
            {/* <span>
              <MdOutlineHelp
                style={{ fontSize: "30px" }}
                className="text-secondary me-2"
              />
              <BsGearFill
                className="text-secondary me-2"
                style={{ fontSize: "25px" }}
              />
            </span> */}
            <Image
              src={notification}
              alt="Logo"
              className="rounded img-fluid me-2"
              style={{ cursor: "pointer", height: "40px" }}
            />
            <Image
              src={setting}
              alt="Logo"
              className="rounded img-fluid me-2"
              style={{ cursor: "pointer", height: "40px" }}
            />
            <Image
              src={logo}
              alt="Logo"
              className="rounded img-fluid me-1"
              style={{ cursor: "pointer", height: "40px" }}
            />
            <span
              className="small ms-1 me-2"
              style={{ fontSize: "16px", fontWeight:500 }}
            >
              Jessica Green <br/>
              <span style={{fontSize:'14px',fontWeight:400, color:'#737791'}}>User</span>
            </span>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
