import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import axios from "axios";
import Loader from "../Spinner/Loader";

// graf data convert into million
const CustomYAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={0} fontSize={15} textAnchor="end" fill="#666">
        {payload.value / 1000000}M
      </text>
    </g>
  );
};
// table header data month wise
const getCurrentMonth = () => {
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const currentMonthIndex = new Date().getMonth();
  return monthNames[currentMonthIndex];
};
// table body data month wise
const getCurrentMonthIndex = () => {
  return new Date().getMonth(); // returns 0 for January, 1 for February, ..., 11 for December
};
const Collection = () => {
  const [loading, setLoading] = useState(false);
  const [openContant, setOpenContant] = useState("contantone");
  const [isActive, setIsActive] = useState(1);
  const [showPopup, setShowPopUp] = useState(false);
  const [showPopupone, setShowPopUpone] = useState(false);
  const [getDash, setGetDash] = useState({});
  const [getDashcard, setGetDashcard] = useState({});
  const [getDashtable, setGetDashtable] = useState({});
  const [getDashtableH, setGetDashtableH] = useState({
    EUR: [],
    GBP: [],
    CAD: [],
    MXP: [],
    HKD: [],
  });
  const [getDashtableLE, setGetDashtableLE] = useState({});
  const [getDashtablepopup, setGetDashtablepopup] = useState([]);
  const [getDashtablepopupall, setGetDashtablepopupall] = useState([]);
  const [getDashtablecur, setGetDashtablecur] = useState("");
  const [getDashtabledate, setGetDashtabledate] = useState("");

  const [showPopupH, setShowPopUpH] = useState(false);
  const [modalDataH, setModalDataH] = useState([]);
  const [modalDataHcur, setModalDataHcur] = useState("");
  const [selectcur, setSelectcur] = useState("");

  useEffect(() => {
    getAllExposure();
    getExposurebycurrency();
    getExposureforcurrency();
    getExposureforcurrencyH();
    getExposureforcurrencyLE();
  }, []);
  // show graf card data
  const getAllExposure = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_BASEURL}all_exposure/?filter_by=current`;
    axios
      .get(url, config)
      .then((res) => {
        setGetDash(res.data.results);
      })
      .catch((err) => {
        console.log(err, "err dashboard");
      });
  };
  // show currency card data
  const getExposurebycurrency = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_BASEURL}exposure_by_currency/?filter_by=current`;
    axios
      .get(url, config)
      .then((res) => {
        setGetDashcard(res.data.results);
      })
      .catch((err) => {
        console.log(err, "err dashboard");
      });
  };
  // show current exposures table data
  const getExposureforcurrency = (cur) => {
    setLoading(true);
    setSelectcur(cur);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = cur
      ? `${process.env.REACT_APP_BASEURL}exposure_for_currency/?filter_by=current&currency=${cur}`
      : `${process.env.REACT_APP_BASEURL}exposure_for_currency/?filter_by=current`;
    axios
      .get(url, config)
      .then((res) => {
        setLoading(false);
        setGetDashtable(res.data.results);
        // console.log(res.data.results[0].EUR, "datajjj");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "err dashboard");
      });
  };
  // show history table data
  const getExposureforcurrencyH = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_BASEURL}exposure_for_history/?filter_by=current`;
    axios
      .get(url, config)
      .then((res) => {
        setGetDashtableH(res.data.results);
        // console.log(res.data.results, "datajjj");
      })
      .catch((err) => {
        console.log(err, "err dashboard");
      });
  };
  // show legal entity table data
  const getExposureforcurrencyLE = (cur) => {
    setSelectcur(cur);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = cur
      ? `${process.env.REACT_APP_BASEURL}exposure_with_entity/?filter_by=current&currency=${cur}`
      : `${process.env.REACT_APP_BASEURL}exposure_with_entity/?filter_by=current`;
    axios
      .get(url, config)
      .then((res) => {
        setGetDashtableLE(res.data.results);
      })
      .catch((err) => {
        console.log(err, "err dashboard");
      });
  };
  // show all month graf data
  const getExposureforTable = (cur) => {
    setGetDashtablecur(cur);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_BASEURL}exposure_for_table/?currency=${cur}`;
    axios
      .get(url, config)
      .then((res) => {
        setGetDashtablepopup(res.data.results);
        // console.log(res.data.results);
      })
      .catch((err) => {
        console.log(err, "err dashboard");
      });
  };
  // show per month graf data
  const getExposureforCurrencyExposure = (cur, dt) => {
    setGetDashtablecur(cur);
    setGetDashtabledate(dt);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = `${process.env.REACT_APP_BASEURL}exposure_for_currency_exposure/?currency=${cur}&filter_by=current&exposure_date=${dt}`;
    axios
      .get(url, config)
      .then((res) => {
        setGetDashtablepopupall(res.data.results);
        // console.log(res.data.results);
      })
      .catch((err) => {
        console.log(err, "err dashboard");
      });
  };
  // both data and datatwo are show line graf static data
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 1800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 5800,
      amt: 2500,
    },
  ];
  const datatwo = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 1800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 2800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 1800,
      amt: 2500,
    },
  ];
  // header show by current month
  const currentMonth = getCurrentMonth();
  const months = [
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
    "JAN",
    "FEB",
    "MAR",
    "APR",
  ];
  // Find the index of the current month
  const currentMonthIndexH = months.indexOf(currentMonth);
  // Rearrange the array so that the current month comes first
  const rearrangedMonths = [
    ...months.slice(currentMonthIndexH),
    ...months.slice(0, currentMonthIndexH),
  ];
  // render history table
  const renderCurrencyRows = (currency, label) => {
    const currencyData = getDashtableH?.[currency] || [];

    const handleClick = (currencyData) => {
      setModalDataH(currencyData);
      setModalDataHcur(label);
      setShowPopUpH(true);
    };
    return (
      <>
        <tr>
          <td className="h5 tbl_crp">
            {currencyData.length > 0 ? label : null}
          </td>
          {currencyData.length > 0 ? (
            currencyData.map((item, index) => (
              <td
                className="h5 tbl_crp"
                key={index}
                onClick={() => handleClick(currencyData)}
              >
                {item ? `${(item.amount / 1000000).toFixed(2)}` : "N/A"}
              </td>
            ))
          ) : (
            <td colSpan="13" className="text-center m-5">
              No data available
            </td>
          )}
        </tr>
        <tr>
          <td className="small h6 text-secondary">
            {currencyData.length > 0 ? `${label} Probability` : null}
          </td>
          {currencyData.length > 0 ? (
            currencyData.map((item, index) => (
              <td className="small h6 text-secondary" key={index}>
                {item ? `${(item.probability * 100).toFixed(2)}%` : "N/A"}
              </td>
            ))
          ) : (
            <td colSpan="13" className="text-center m-5">
              No data available
            </td>
          )}
        </tr>
      </>
    );
  };
  // render current exposures table month wise
  const currentMonthIndex = getCurrentMonthIndex();
  const getReorderedMonths = () => {
    const months = [...Array(12).keys()]; // [0, 1, 2, ..., 11]
    return [
      ...months.slice(currentMonthIndex),
      ...months.slice(0, currentMonthIndex),
    ];
  };
  const reorderedMonths = getReorderedMonths();
  //other
  return (
    <>
      {loading && <Loader />}
      <Header />
      <Container fluid className="pt-4">
        <Row className="ps-3">
          <h6 style={{ fontWeight: 600, fontSize: "24px" }}>
            Exposure Collection
          </h6>
          <p
            style={{ fontWeight: 400, fontSize: "14px" }}
            className="text-secondary"
          >
            My objective is to collect forecast FX exposures
          </p>
          <Col sm={12} md={12} lg={6} className="mt-3">
            <p style={{ fontWeight: 500, fontSize: "18px", color: "#02A6D9" }}>
              Total absolute forecast exposure
            </p>
            <Card className="border-1 border border-light-subtle shadow">
              <Card.Body className="text-center p-4">
                <Row>
                  <Col sm={12} md={12} lg={6}>
                    <span style={{ fontSize: "50px", fontWeight: 500 }}>
                      {/* {getDash && typeof getDash.sum_of_amount === "number"
                        ? `$ ${(getDash.sum_of_amount / 1000000).toFixed(0)}M`
                        : "N/A"} */}
                      {getDashcard &&
                      getDashcard.GBP &&
                      typeof getDashcard.GBP.amount === "number" &&
                      getDashcard.EUR &&
                      typeof getDashcard.EUR.amount === "number" &&
                      getDashcard.CAD &&
                      typeof getDashcard.CAD.amount === "number" &&
                      getDashcard.MXP &&
                      typeof getDashcard.MXP.amount === "number" &&
                      getDashcard.HKD &&
                      typeof getDashcard.HKD.amount === "number"
                        ? `$ ${(
                            (getDashcard.GBP.amount * 1.25 +
                              getDashcard.EUR.amount * 1.08 +
                              getDashcard.CAD.amount * 0.73 +
                              getDashcard.MXP.amount * 0.06 +
                              getDashcard.HKD.amount * 0.13) /
                            1000000
                          ).toFixed(0)}M`
                        : "N/A"}
                    </span>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={data} width="100%" height="100%">
                        <Line
                          type="monotone"
                          dataKey="pv"
                          stroke="#1aa4c8"
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={12} lg={6} className="mt-3">
            <p style={{ fontWeight: 500, fontSize: "18px", color: "#02A6D9" }}>
              Weighted average certainty
            </p>
            <Card className="border-1 border border-light-subtle shadow">
              <Card.Body className="text-center p-4">
                <Row>
                  <Col sm={12} md={12} lg={6}>
                    <span style={{ fontSize: "50px", fontWeight: 500 }}>
                      {getDash && typeof getDash.avg_probability === "number"
                        ? (getDash.avg_probability * 100).toFixed(2)
                        : "N/A"}
                      %
                    </span>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={datatwo} width="100%" height="100%">
                        <Line
                          type="monotone"
                          dataKey="pv"
                          stroke="#00d5ae"
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4 d-flex justify-content-evenly ps-3">
          <Col sm={12} md={12} lg={12} className="mt-3 d-flex">
            <p style={{ fontWeight: 500, fontSize: "18px", color: "#02A6D9" }}>
              Exposures by:
            </p>
            {/* <Form className="p-1 ms-4">
              <Form.Check
                inline
                label="Currency"
                name="group1"
                type="radio"
                id="radio1"
                className="ms-5"
              />
              <Form.Check
                inline
                label="Legal Entity"
                name="group1"
                type="radio"
                id="radio2"
                className="ms-5"
              />
              <Form.Check
                inline
                label="Month"
                name="group1"
                type="radio"
                id="radio3"
                className="ms-5"
              />
            </Form> */}
          </Col>
          <Col sm={12} md={6} lg={2} className="mt-3">
            <Card
              style={{ cursor: "pointer" }}
              className={`shadow ${
                selectcur === getDashcard?.GBP?.currency
                  ? "active-card border-1 border"
                  : "border-1 border"
              }`}
              onClick={() => {
                getDashcard?.GBP?.currency &&
                  getExposureforcurrency(getDashcard.GBP.currency);
                getExposureforcurrencyLE(getDashcard.GBP.currency);
              }}
            >
              <Card.Body className="text-center p-4">
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                  className="mb-0 mt-2"
                >
                  {getDashcard &&
                  getDashcard.GBP &&
                  typeof getDashcard.GBP.currency === "string"
                    ? getDashcard.GBP.currency
                    : "N/A"}
                </h3>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#737791",
                  }}
                >
                  Forecast exposure
                </small>
                <h4
                  style={{ fontSize: "28px", fontWeight: 600 }}
                  className="mb-0"
                >
                  {getDashcard &&
                  getDashcard.GBP &&
                  typeof getDashcard.GBP.amount === "number"
                    ? (getDashcard.GBP.amount / 1000000).toFixed(2)
                    : "N/A"}
                  M
                </h4>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                >
                  {getDashcard &&
                  getDashcard.GBP &&
                  typeof getDashcard.GBP.amount === "number"
                    ? ((getDashcard.GBP.amount * 1.25) / 1000000).toFixed(2)
                    : "N/A"}
                  M USD
                </small>
                <span className="card_border">
                  {getDashcard?.GBP?.probability &&
                  typeof getDashcard.GBP.probability === "number" ? (
                    <ProgressBar
                      now={getDashcard.GBP.probability * 100}
                      label={`${(getDashcard.GBP.probability * 100).toFixed(
                        2
                      )}%`}
                      className="custom-progress-bar"
                    />
                  ) : (
                    <ProgressBar now={0} label="0%" variant="info" />
                  )}
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={2} className="mt-3">
            <Card
              style={{ cursor: "pointer" }}
              className={`shadow ${
                selectcur === getDashcard?.EUR?.currency
                  ? "active-card border-1 border"
                  : "border-1 border"
              }`}
              onClick={() => {
                getDashcard?.EUR?.currency &&
                  getExposureforcurrency(getDashcard.EUR.currency);
                getExposureforcurrencyLE(getDashcard.EUR.currency);
              }}
            >
              <Card.Body className="text-center p-4">
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                  className="mb-0 mt-2"
                >
                  {getDashcard &&
                  getDashcard.EUR &&
                  typeof getDashcard.EUR.currency === "string"
                    ? getDashcard.EUR.currency
                    : "N/A"}
                </h3>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#737791",
                  }}
                >
                  Forecast exposure
                </small>
                <h4
                  style={{ fontSize: "28px", fontWeight: 600 }}
                  className="mb-0"
                >
                  {getDashcard &&
                  getDashcard.EUR &&
                  typeof getDashcard.EUR.amount === "number"
                    ? (getDashcard.EUR.amount / 1000000).toFixed(2)
                    : "N/A"}
                  M
                </h4>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                >
                  {getDashcard &&
                  getDashcard.EUR &&
                  typeof getDashcard.EUR.amount === "number"
                    ? ((getDashcard.EUR.amount * 1.08) / 1000000).toFixed(2)
                    : "N/A"}
                  M USD
                </small>
                <span className="card_border">
                  {getDashcard?.EUR?.probability &&
                  typeof getDashcard.EUR.probability === "number" ? (
                    <ProgressBar
                      now={getDashcard.EUR.probability * 100}
                      label={`${(getDashcard.EUR.probability * 100).toFixed(
                        2
                      )}%`}
                      className="custom-progress-bar"
                    />
                  ) : (
                    <ProgressBar now={0} label="0%" variant="info" />
                  )}
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={2} className="mt-3">
            <Card
              style={{ cursor: "pointer" }}
              className={`shadow ${
                selectcur === getDashcard?.CAD?.currency
                  ? "active-card border-1 border"
                  : "border-1 border"
              }`}
              onClick={() => {
                getDashcard?.CAD?.currency &&
                  getExposureforcurrency(getDashcard.CAD.currency);
                getExposureforcurrencyLE(getDashcard.CAD.currency);
              }}
            >
              <Card.Body className="text-center p-4">
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                  className="mb-0 mt-2"
                >
                  {getDashcard &&
                  getDashcard.CAD &&
                  typeof getDashcard.CAD.currency === "string"
                    ? getDashcard.CAD.currency
                    : "N/A"}
                </h3>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#737791",
                  }}
                >
                  Forecast exposure
                </small>
                <h4
                  style={{ fontSize: "28px", fontWeight: 600 }}
                  className="mb-0"
                >
                  {getDashcard &&
                  getDashcard.CAD &&
                  typeof getDashcard.CAD.amount === "number"
                    ? (getDashcard.CAD.amount / 1000000).toFixed(2)
                    : "N/A"}
                  M
                </h4>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                >
                  {getDashcard &&
                  getDashcard.CAD &&
                  typeof getDashcard.CAD.amount === "number"
                    ? ((getDashcard.CAD.amount * 0.73) / 1000000).toFixed(2)
                    : "N/A"}
                  M USD
                </small>
                <span className="card_border">
                  {getDashcard?.CAD?.probability &&
                  typeof getDashcard.CAD.probability === "number" ? (
                    <ProgressBar
                      now={getDashcard.CAD.probability * 100}
                      label={`${(getDashcard.CAD.probability * 100).toFixed(
                        2
                      )}%`}
                      className="custom-progress-bar"
                    />
                  ) : (
                    <ProgressBar now={0} label="0%" variant="info" />
                  )}
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={2} className="mt-3">
            <Card
              style={{ cursor: "pointer" }}
              className={`shadow ${
                selectcur === getDashcard?.MXP?.currency
                  ? "active-card border-1 border"
                  : "border-1 border"
              }`}
              onClick={() => {
                getDashcard?.MXP?.currency &&
                  getExposureforcurrency(getDashcard.MXP.currency);
                getExposureforcurrencyLE(getDashcard.MXP.currency);
              }}
            >
              <Card.Body className="text-center p-4">
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                  className="mb-0 mt-2"
                >
                  {getDashcard &&
                  getDashcard.MXP &&
                  typeof getDashcard.MXP.currency === "string"
                    ? getDashcard.MXP.currency
                    : "N/A"}
                </h3>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#737791",
                  }}
                >
                  Forecast exposure
                </small>
                <h4
                  style={{ fontSize: "28px", fontWeight: 600 }}
                  className="mb-0"
                >
                  {getDashcard &&
                  getDashcard.MXP &&
                  typeof getDashcard.MXP.amount === "number"
                    ? (getDashcard.MXP.amount / 1000000).toFixed(2)
                    : "N/A"}
                  M
                </h4>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                >
                  {getDashcard &&
                  getDashcard.MXP &&
                  typeof getDashcard.MXP.amount === "number"
                    ? ((getDashcard.MXP.amount * 0.06) / 1000000).toFixed(2)
                    : "N/A"}
                  M USD
                </small>
                <span className="card_border">
                  {getDashcard?.MXP?.probability &&
                  typeof getDashcard.MXP.probability === "number" ? (
                    <ProgressBar
                      now={getDashcard.MXP.probability * 100}
                      label={`${(getDashcard.MXP.probability * 100).toFixed(
                        2
                      )}%`}
                      className="custom-progress-bar"
                    />
                  ) : (
                    <ProgressBar now={0} label="0%" variant="info" />
                  )}
                </span>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={2} className="mt-3">
            <Card
              style={{ cursor: "pointer" }}
              className={`shadow ${
                selectcur === getDashcard?.HKD?.currency
                  ? "active-card border-1 border"
                  : "border-1 border"
              }`}
              onClick={() => {
                getDashcard?.HKD?.currency &&
                  getExposureforcurrency(getDashcard.HKD.currency);
                getExposureforcurrencyLE(getDashcard.HKD.currency);
              }}
            >
              <Card.Body className="text-center p-4">
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                  className="mb-0 mt-2"
                >
                  {getDashcard &&
                  getDashcard.HKD &&
                  typeof getDashcard.HKD.currency === "string"
                    ? getDashcard.HKD.currency
                    : "N/A"}
                </h3>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#737791",
                  }}
                >
                  Forecast exposure
                </small>
                <h4
                  style={{ fontSize: "28px", fontWeight: 600 }}
                  className="mb-0"
                >
                  {getDashcard &&
                  getDashcard.HKD &&
                  typeof getDashcard.HKD.amount === "number"
                    ? (getDashcard.HKD.amount / 1000000).toFixed(2)
                    : "N/A"}
                  M
                </h4>
                <small
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#02A6D9",
                  }}
                >
                  {getDashcard &&
                  getDashcard.HKD &&
                  typeof getDashcard.HKD.amount === "number"
                    ? ((getDashcard.HKD.amount * 0.13) / 1000000).toFixed(2)
                    : "N/A"}
                  M USD
                </small>
                <span className="card_border">
                  {getDashcard?.HKD?.probability &&
                  typeof getDashcard.HKD.probability === "number" ? (
                    <ProgressBar
                      now={getDashcard.HKD.probability * 100}
                      label={`${(getDashcard.HKD.probability * 100).toFixed(
                        2
                      )}%`}
                      className="custom-progress-bar"
                    />
                  ) : (
                    <ProgressBar now={0} label="0%" variant="info" />
                  )}
                </span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} className="mt-3 mb-3">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <span
                  className={
                    isActive === 1
                      ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                      : "d-flex align-items-center mt-3 ms-3"
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenContant("contantone");
                    setIsActive(1);
                  }}
                >
                  <p className="fw-normal h6">Current exposures</p>
                </span>
                <span
                  className={
                    isActive === 2
                      ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                      : "d-flex align-items-center mt-3 ms-3"
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenContant("contanttwo");
                    setIsActive(2);
                  }}
                >
                  <p className="fw-normal h6">Legal Entity</p>
                </span>
                <span
                  className={
                    isActive === 3
                      ? "d-flex align-items-center mt-3 ms-3 card Actcard"
                      : "d-flex align-items-center mt-3 ms-3"
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenContant("contantthree");
                    setIsActive(3);
                  }}
                >
                  <p className="fw-normal h6">Exposure history</p>
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <span
                  style={{
                    color: "#fff",
                    backgroundColor: "#49b7b7",
                    borderColor: "#49b7b7",
                    padding: "5px 40px",
                  }}
                  onClick={() => {
                    getExposureforcurrency();
                    getExposureforcurrencyLE();
                  }}
                  className="btn me-2"
                >
                  Show All
                </span>
              </div>
            </div>
            {openContant == "contantone" ? (
              <>
                <Container fluid>
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={12}
                      className="mt-4 card shadow border-0"
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th></th>
                            <th className="small h6 text-secondary">TOTAL</th>
                            {rearrangedMonths.map((month, index) => (
                              <th
                                key={index}
                                className={`small h6 text-secondary ${
                                  month === currentMonth ? "" : ""
                                }`}
                              >
                                {month}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(getDashtable) &&
                          getDashtable.length > 0 ? (
                            getDashtable.map((currencyData, index) => {
                              const currencyElements = [];
                              for (let currencyCode in currencyData) {
                                const currency = currencyData[currencyCode];
                                currencyElements.push(
                                  <React.Fragment
                                    key={`${index}_${currencyCode}`}
                                  >
                                    <tr>
                                      <td className="h5 tbl_crp">
                                        {currency.currency}
                                      </td>
                                      <td
                                        className="h5 tbl_crp"
                                        onClick={() => {
                                          setShowPopUp(true);
                                          getExposureforTable(
                                            currency.currency
                                          );
                                        }}
                                      >
                                        {(currency.amount / 1000000).toFixed(2)}
                                      </td>
                                      {reorderedMonths.map((monthIndex) => (
                                        <td
                                          key={monthIndex}
                                          className={`h5 tbl_crp ${
                                            monthIndex === currentMonthIndex
                                              ? ""
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setShowPopUpone(true);
                                            getExposureforCurrencyExposure(
                                              currency.currency,
                                              currency[
                                                `exposure_date${monthIndex + 1}`
                                              ]
                                            );
                                          }}
                                        >
                                          {(
                                            currency[
                                              `amount${monthIndex + 1}`
                                            ] / 1000000
                                          ).toFixed(2)}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <td className="small h6 text-secondary">
                                        {currency.currency} Probability
                                      </td>
                                      <td className="small h6 text-secondary">
                                        {(currency.probability * 100).toFixed(
                                          2
                                        )}
                                        %
                                      </td>
                                      {reorderedMonths.map((monthIndex) => (
                                        <td
                                          key={monthIndex}
                                          className={`small h6 text-secondary ${
                                            monthIndex === currentMonthIndex
                                              ? ""
                                              : ""
                                          }`}
                                        >
                                          {(
                                            currency[`prob${monthIndex + 1}`] *
                                            100
                                          ).toFixed(2)}
                                          %
                                        </td>
                                      ))}
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                              return currencyElements;
                            })
                          ) : (
                            <tr>
                              <td colSpan="14" className="text-center m-5">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </>
            ) : openContant == "contanttwo" ? (
              <>
                <Container fluid>
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={12}
                      className="mt-4 card shadow border-0"
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th></th>
                            <th className="small h6 text-secondary">TOTAL</th>
                            {rearrangedMonths.map((month, index) => (
                              <th
                                key={index}
                                className={`small h6 text-secondary ${
                                  month === currentMonth ? "" : ""
                                }`}
                              >
                                {month}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(getDashtableLE) &&
                          getDashtableLE.length > 0 ? (
                            getDashtableLE.map((currencyData, index) => {
                              const currencyElements = [];
                              for (let currencyCode in currencyData) {
                                const currency = currencyData[currencyCode];
                                currencyElements.push(
                                  <React.Fragment
                                    key={`${index}_${currencyCode}`}
                                  >
                                    <tr>
                                      <td className="h5 tbl_crp">
                                        {currency.currency}
                                      </td>
                                      <td
                                        className="h5 tbl_crp"
                                        onClick={() => {
                                          setShowPopUp(true);
                                          getExposureforTable(
                                            currency.currency
                                          );
                                        }}
                                      >
                                        {(currency.amount / 1000000).toFixed(2)}
                                      </td>
                                      {reorderedMonths.map((monthIndex) => (
                                        <td
                                          key={monthIndex}
                                          className={`h5 tbl_crp ${
                                            monthIndex === currentMonthIndex
                                              ? ""
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setShowPopUpone(true);
                                            getExposureforCurrencyExposure(
                                              currency.currency,
                                              currency[
                                                `exposure_date${monthIndex + 1}`
                                              ]
                                            );
                                          }}
                                        >
                                          {(
                                            currency[
                                              `amount${monthIndex + 1}`
                                            ] / 1000000
                                          ).toFixed(2)}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <td className="small h6 text-secondary">
                                        Entity One
                                      </td>
                                      <td className="small h6 text-secondary">
                                        {(
                                          currency.entity_one / 1000000
                                        ).toFixed(2)}
                                      </td>
                                      {reorderedMonths.map((monthIndex) => (
                                        <td
                                          key={monthIndex}
                                          className={`small h6 text-secondary ${
                                            monthIndex === currentMonthIndex
                                              ? ""
                                              : ""
                                          }`}
                                        >
                                          {(
                                            currency[
                                              `entity_one${monthIndex + 1}`
                                            ] / 1000000
                                          ).toFixed(2)}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <td className="small h6 text-secondary">
                                        Entity Two
                                      </td>
                                      <td className="small h6 text-secondary">
                                        {(
                                          currency.entity_two / 1000000
                                        ).toFixed(2)}
                                      </td>
                                      {reorderedMonths.map((monthIndex) => (
                                        <td
                                          key={monthIndex}
                                          className={`small h6 text-secondary ${
                                            monthIndex === currentMonthIndex
                                              ? ""
                                              : ""
                                          }`}
                                        >
                                          {(
                                            currency[
                                              `entity_two${monthIndex + 1}`
                                            ] / 1000000
                                          ).toFixed(2)}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <td className="small h6 text-secondary">
                                        Entity Three
                                      </td>
                                      <td className="small h6 text-secondary">
                                        {(
                                          currency.entity_three / 1000000
                                        ).toFixed(2)}
                                      </td>
                                      {reorderedMonths.map((monthIndex) => (
                                        <td
                                          key={monthIndex}
                                          className={`small h6 text-secondary ${
                                            monthIndex === currentMonthIndex
                                              ? ""
                                              : ""
                                          }`}
                                        >
                                          {(
                                            currency[
                                              `entity_three${monthIndex + 1}`
                                            ] / 1000000
                                          ).toFixed(2)}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <td className="small h6 text-secondary">
                                        Entity Four
                                      </td>
                                      <td className="small h6 text-secondary">
                                        {(
                                          currency.entity_four / 1000000
                                        ).toFixed(2)}
                                      </td>
                                      {reorderedMonths.map((monthIndex) => (
                                        <td
                                          key={monthIndex}
                                          className={`small h6 text-secondary ${
                                            monthIndex === currentMonthIndex
                                              ? ""
                                              : ""
                                          }`}
                                        >
                                          {(
                                            currency[
                                              `entity_four${monthIndex + 1}`
                                            ] / 1000000
                                          ).toFixed(2)}
                                        </td>
                                      ))}
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                              return currencyElements;
                            })
                          ) : (
                            <tr>
                              <td colSpan="14" className="text-center m-5">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </>
            ) : openContant == "contantthree" ? (
              <>
                <Container fluid>
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={12}
                      className="mt-4 card shadow border-0"
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th></th>
                            {rearrangedMonths.map((month, index) => (
                              <th
                                key={index}
                                className={`small h6 text-secondary ${
                                  month === currentMonth ? "" : ""
                                }`}
                              >
                                {month}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {renderCurrencyRows("EUR", "EUR")}
                          {renderCurrencyRows("GBP", "GBP")}
                          {renderCurrencyRows("CAD", "CAD")}
                          {renderCurrencyRows("MXP", "MXP")}
                          {renderCurrencyRows("HKD", "HKD")}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
      {/* =======================popup total======================= */}
      <Modal
        show={showPopup}
        onHide={() => {
          setShowPopUp(false);
        }}
        size="lg"
        scrollable
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8" }}>
          <Modal.Title className="text-white">
            {getDashtablecur ? getDashtablecur : "N/A"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <ResponsiveContainer width={750} height={500}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={getDashtablepopup}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="month" scale="band" />
                    <YAxis tick={<CustomYAxisTick />} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="amount" barSize={20} fill="#00d5ae" />
                    <Line type="monotone" dataKey="amount" stroke="#1aa4c8" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* =======================popup monthley======================== */}
      <Modal
        show={showPopupone}
        onHide={() => {
          setShowPopUpone(false);
        }}
        size="lg"
        scrollable
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8" }}>
          <Modal.Title className="text-white">
            Next 12 Months {getDashtablecur ? getDashtablecur : "N/A"} Exposure
            from{" "}
            {getDashtabledate
              ? new Date(getDashtabledate).toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })
              : "N/A"}{" "}
            onwards
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <ResponsiveContainer width={750} height={500}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={getDashtablepopupall}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="month" scale="band" />
                    <YAxis tick={<CustomYAxisTick />} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="amount" barSize={20} fill="#00d5ae" />
                    <Line type="monotone" dataKey="amount" stroke="#1aa4c8" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* =======================history popup monthley======================== */}
      <Modal
        show={showPopupH}
        onHide={() => {
          setShowPopUpH(false);
        }}
        size="lg"
        scrollable
      >
        <Modal.Header closeButton style={{ backgroundColor: "#1AA4C8" }}>
          <Modal.Title className="text-white">
            Exposure History for {modalDataHcur ? modalDataHcur : "N/A"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <ResponsiveContainer width={750} height={500}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={modalDataH}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="month" scale="band" />
                    <YAxis tick={<CustomYAxisTick />} />
                    <Tooltip />
                    <Bar dataKey="amount" barSize={20} fill="#00d5ae" />
                    <Line type="monotone" dataKey="amount" stroke="#1aa4c8" />
                  </ComposedChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Collection;
