import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import Ecosystum from "../../Assets/Ecosystum.png";
import Information from "../../Assets/information.png"
import AIderiven from "../../Assets/AIdriven.png"
import Immersive from "../../Assets/Immersive.png"
import Simulated from "../../Assets/Simulated.png"
const Platfrom = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center pt-5 container-fluid' style={{ backgroundColor: '#F5F6FA' }}>
            <div className=" ">
                <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                    <div className='d-flex justify-content-center' >
                        <div className="col-lg-12 ">
                            <p style={{ fontSize: '18px', fontWeight: '500', color: '#02A6D9' }} className="title text-center">The Platform</p>
                            <h5 style={{ fontSize: '28px', fontWeight: '500', color: '#0F3659' }} className="title text-center">Fusion Treasury: The Integrated Digital Twin Platform</h5>
                            <p className="mt-2 text-center" style={{ fontSize: '16px', color: '#737791', textAlign: 'justify', fontWeight: '400', }}>
                                An advanced digital platform designed to revolutionize treasury management by integrating real-time data, collaborative decision-making,<br></br>
                                and predictive analytics powered by AI based Autonomous Digital Agents Framework
                            </p>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center mt-2'   >
                    <Row className='col-10 '>
                        <Col lg={4} sm={12} className='mt-3' >
                            <Card style={{ padding: '10px', margin: '10px', borderRadius: '10px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Card.Body className='text-center'>
                                    <Card.Title style={{ fontSize: '20px', fontWeight: '500', color: '#0F3659', textAlign: 'justify' }}>
                                        <img src={Ecosystum} alt="icon" style={{ marginRight: '10px', width: '50px', height: '50px' }} />Collaborative Ecosystem</Card.Title>
                                    <Card.Text style={{ fontSize: '18px', color: '#737791', fontWeight: '400', textAlign: 'justify' }}>
                                        Connects all treasury stakeholders within a virtual
                                        environment, facilitating shared decision-making
                                        with real-time visibility.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} sm={12} className='mt-3'>
                            <Card style={{ padding: '10px', margin: '10px', borderRadius: '10px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Card.Body className='text-center'>
                                    <Card.Title style={{ fontSize: '20px', fontWeight: '500', color: '#0F3659', textAlign: 'justify' }}>
                                        <img src={Information} alt="icon" style={{ marginRight: '10px', width: '50px', height: '50px' }} />Information Hub</Card.Title>
                                    <Card.Text style={{ fontSize: '18px', color: '#737791', fontWeight: '400', textAlign: 'justify' }}>
                                        Consolidates financial data from across the
                                        organization and external sources, providing a
                                        single source of truth for treasury operations.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} sm={12} className='mt-3'>
                            <Card style={{ padding: '10px', margin: '10px', borderRadius: '10px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Card.Body className='text-center'>
                                    <Card.Title style={{ fontSize: '20px', fontWeight: '500', color: '#0F3659', textAlign: 'justify' }}>
                                        <img src={AIderiven} alt="icon" style={{ marginRight: '10px', width: '50px', height: '50px' }} />AI-Driven Intelligence</Card.Title>
                                    <Card.Text style={{ fontSize: '18px', color: '#737791', fontWeight: '400', textAlign: 'justify' }}>
                                        Employs sophisticated algorithms to deliver
                                        actionable insights, forecast financial trends, and
                                        inform strategic decisions
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className='d-flex justify-content-center mt-2'   >
                    <Row className='col-10 '>
                        <Col lg={4} sm={12} className='mt-3' >
                            <Card style={{ padding: '10px', margin: '10px', borderRadius: '10px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Card.Body className='text-center'>
                                    <Card.Title style={{ fontSize: '20px', fontWeight: '500', color: '#0F3659', textAlign: 'justify' }}>
                                        <img src={Simulated} alt="icon" style={{ marginRight: '10px', width: '50px', height: '50px' }} />Simulated Strategy</Card.Title>
                                    <Card.Text style={{ fontSize: '18px', color: '#737791', fontWeight: '400', textAlign: 'justify' }}>
                                        Models and optimizes transaction strategies
                                        without executing them, serving as a decision-
                                        support tool for trusted and controlled transactions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} sm={12} className='mt-3'>
                            <Card style={{ padding: '21px', margin: '10px', borderRadius: '10px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Card.Body className='text-center'>
                                    <Card.Title style={{ fontSize: '20px', fontWeight: '500', color: '#0F3659', textAlign: 'justify' }}>
                                        <img src={Immersive} alt="icon" style={{ marginRight: '10px', width: '50px', height: '50px' }} />Immersive Experience</Card.Title>
                                    <Card.Text style={{ fontSize: '18px', color: '#737791', fontWeight: '400', textAlign: 'justify' }}>
                                        Offers an integrated treasury metaverse, where
                                        users can engage with financial scenarios through
                                        an intuitive,interactive platform.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} sm={12} className='mt-3'>
                            <Card style={{ padding: '25px', margin: '10px', borderRadius: '10px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Card.Body className='text-center'>
                                    <Card.Title style={{ fontSize: '20px', fontWeight: '500', color: '#0F3659', textAlign: 'justify', display: 'none' }}>
                                        <img src={Immersive} alt="icon" style={{ marginRight: '10px', width: '20px', height: '20px' }} />Immersive Experience</Card.Title>
                                    <Card.Text style={{ fontSize: '18px', color: '#737791', fontWeight: '400', textAlign: 'justify' }}>
                                        Fusion Treasury serves as an analytical overlay
                                        to transactional systems, amplifying strategic
                                        planning and operational efficiency with the
                                        depth and dynamism of a digital twin technology
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Platfrom