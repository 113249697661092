import React, { useState } from "react";
import { FaUsers, FaCalculator } from "react-icons/fa";
import { CgMenuLeft } from "react-icons/cg";
import { FaFolderTree } from "react-icons/fa6";
import { SiGoogleanalytics } from "react-icons/si";
import { IoCube } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import { NavLink } from "react-router-dom";
import logoside from "../../Assets/logo_img_white.png";
const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/dashboard",
      name: "Workforce",
      icon: <FaUsers />,
    },
    {
      path: "/collection",
      name: "Collection",
      icon: <FaFolderTree />,
    },
    {
      path: "/calculator",
      name: "Calculator",
      icon: <FaCalculator />,
    },
    {
      path: "/analytics",
      name: "Analytics",
      icon: <SiGoogleanalytics />,
    },
    {
      path: "/product",
      name: "Product",
      icon: <IoCube />,
    },
    {
      path: "/productList",
      name: "Product List",
      icon: <LuCalendarDays />,
    },
  ];
  return (
    <div className="container_sidebar">
      <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
        <div className="top_section">
          <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
            <img
              src={logoside}
              className="img-fluid"
              style={{ maxWidth: "90px" }}
              alt="dot"
            />
          </h1>
          <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
            <CgMenuLeft onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="Nav_link"
            activeclassName="active"
          >
            <div className="icons">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
