import React from "react";

const ProductList = () => {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <h1>product list page</h1>
      </div>
    </>
  );
};

export default ProductList;
