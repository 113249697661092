import React from "react";
import HeaderLP from "./HeaderLP";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../Assets/banner.jpg";
import { Button, Col, Container, Row } from "react-bootstrap";
import DigitalAgents from "./DigitalAgents";
import Introduction from "./Introduction";
import Platfrom from "./Platform";
import Overview from "./Overview";
import AutonomousAgent from "./AutonomousAgent";
import Footer from "../Footer/Footer";
import Beyond from "./Beyond";
import AgentAction from "./AgentAction";

const LandingPage = () => {
  const navigate = useNavigate();
  const containerStyle = {
    background: `url(${backgroundImage}) center/cover no-repeat`,
    minHeight: "100vh",
  };
  return (
    <>
      <HeaderLP />
      <div style={containerStyle}>
        <div
          className="container d-flex align-items-center "
          style={{ paddingTop: "6%" }}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col
              lg={6}
              sm={12}
              className="d-flex flex-column justify-content-center align-items-start bg_res"
            >
              <h3
                style={{
                  color: "#0BD0D9",
                  fontSize: "50px",
                  fontWeight: 600,
                  marginTop: "150px",
                }}
              >
                Delivering
              </h3>
              <h2
                style={{
                  color: "#fff",
                  fontSize: "100px",
                  fontWeight: 700,
                  lineHeight: "100px",
                }}
              >
                Exponential Insights
              </h2>
              <div style={{ marginTop: "20px" }}>
                <Button
                  className="BTN_Color"
                  size="lg"
                  style={{
                    borderRadius: 10,
                    minWidth: 100,
                    borderColor: "#1aa4c8",
                  }}
                  onClick={() => navigate("/login")}
                >
                  <div>Login</div>
                </Button>
              </div>
            </Col>
            <Col lg={6} sm={12} className="d-flex justify-content-center"></Col>
          </Row>
        </div>
      </div>
      <Introduction />
      <Platfrom />
      <Overview/>
      <AutonomousAgent/>
      <DigitalAgents />
      <Beyond/>
      <AgentAction/>
      <Footer/>   
    </>
  );
};

export default LandingPage;
