import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function HeaderLP() {
  const navigate = useNavigate();
  const [navbarBackground, setNavbarBackground] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbarTransparent = scrollPosition < 50;
      setNavbarBackground(
        navbarTransparent
          ? "transparent"
          : "linear-gradient(to right, #000 ,#000)"
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="sm"
        style={{
          transition: "background 0.5s ease",
          background: navbarBackground,
        }}
      >
        <Container>
          <Navbar.Brand style={{ cursor: "pointer" }}>
            <img
              alt="Short logo"
              src={require("../../Assets/logo_img_blue.png")}
              className="d-lg-inline-block align-top"
              style={{ width: "220px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto align-items-center ">
              <Nav.Link href="">
                <Button
                  className="BTN_Color"
                  size="lg"
                  style={{
                    borderRadius: 10,
                    minWidth: 100,
                    borderColor: "#1aa4c8",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default HeaderLP;
