import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
const Introduction = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center pt-5 container-fluid'style={{ backgroundColor: '#F5F6FA' }}>
            <div className=" ">
                <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                    <div className='d-flex justify-content-center' >
                        <div className="col-lg-12 ">
                            <h5 style={{ fontSize: '20px', fontWeight: '500', color: '#02A6D9' }} className="title text-center">Fusion Treasury : Introduction </h5>
                            <p className="mt-2 text-center" style={{ fontSize: '33px', color: '#000', textAlign: 'justify', fontWeight: '500', }}>
                                What is Fusion Treasury
                            </p>
                        </div>

                    </div>
                </div>
                <div className='d-flex align-items-center ' style={{ minHeight: '50vh' }}>
                    <Row className='d-flex justify-content-center align-items-center '>
                        <Col lg={5} sm={12} className='d-flex justify-content-center'>
                            <img src={require('../../Assets/fusionintro.png')} alt="cart" className="img-fluid" style={{ width: '65%' }}></img>
                        </Col>
                        <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                            <p style={{ fontSize: '25px', fontWeight: '500', color: '#02A6D9', textAlign: 'justify', }}>The Fusion Treasury platform is shaping the future of treasury management by:</p>
                            <p style={{ fontSize: '19px', color: '#737791', textAlign: 'justify', lineHeight:'35px'}}>Facilitating matchmaking, unified interactions, and collaboration between
                                all stakeholders in treasury management.</p>
                            <p style={{ fontSize: '19px', color: '#737791', textAlign: 'justify', lineHeight:'35px'}}>Gathering all critical treasury information* into a one place. Then going beyond
                                traditional business intelligence, it provides Al-led deep insights across multiple
                                information sources and systems.</p>
                            <p style={{ fontSize: '19px', color: '#737791', textAlign: 'justify',lineHeight:'35px' }}>Enabling trusted and controlled transactions with all vendors and providers via
                                new unified experiences within a treasury metaverse continuum.</p>
                        </Col>

                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Introduction