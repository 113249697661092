import React, { useState } from "react";
import { Col } from "react-bootstrap";
import logo from "../../Assets/logo_darkblue.png";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    // Replace hardcoded values with states
    const hardcodedEmail = "ft@tech-radix.com";
    const hardcodedPassword = "radixedge#1";

    // Check if the entered credentials match the hardcoded ones
    if (email === hardcodedEmail && password === hardcodedPassword) {
      // Navigate to the dashboard if the credentials are correct
      navigate('/dashboard');
    } else {
      // Display an error message or handle invalid credentials
      alert("Invalid email or password. Please try again.");
    }
  };
  return (
    <>
      <div style={{ marginBottom: "120px", marginTop: "140px" }}>
        <div className="d-flex justify-content-center ">
          <Col lg={6}>
            <div
              className="card card-body p-5 "
              style={{ boxShadow: "-1px 1px 15px 4px #9289897a" }}
            >
              <form className="p-3 text-center" onSubmit={handleLogin}>
                <img
                  src={logo}
                  style={{ marginTop: "-40px", height: "100px" }}
                  alt="title"
                ></img>
                <h3 className="mt-4 mb-0">Login</h3>
                <div
                  className="form-group d-flex flex-column justify-content-start mt-0"
                  style={{ marginTop: "20px" }}
                >
                  <label style={{ textAlign: "left" }}>Email</label>
                  <input
                    type="email"
                    className="form-control col-12 p-2"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    name="email"
                    style={{ height: "50px" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div
                  className="form-group d-flex flex-column  justify-content-start"
                  style={{ marginTop: "10px" }}
                >
                  <label style={{ textAlign: "left" }}>Password</label>
                  <div className="input-group">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control col-12 p-3"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      style={{ height: "50px", borderRight: "none" }}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text "
                        style={{
                          borderLeft: "none",
                          background: "white",
                          height: "50px",
                          marginLeft: "-5px",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <AiOutlineEyeInvisible width={20} height={40} />
                        ) : (
                          <AiFillEye width={20} height={20} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  className="btn col-12 mt-3 p-2 BTN_Color"
                  style={{ color: "white" }}
                  type="submit"
                >
                  Login
                </button>
              </form>
            </div>
          </Col>
        </div>
      </div>
    </>
  );
};

export default Login;
