import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import backgroundImage from "../../Assets/blue_bg.png";
import Logoimage from "../../Assets/logo_img_blue.png";
import Logoimg from "../../Assets/logo_darkblue.png";
import iconimg1 from "../../Assets/cc_img1.png";
import iconimg2 from "../../Assets/cc_img2.png";
import iconimg39 from "../../Assets/cc_img39.png";
import iconimg4 from "../../Assets/cc_img4.png";
import iconimg5 from "../../Assets/cc_img5.png";
import iconimg6 from "../../Assets/cc_img6.png";
import iconimg7 from "../../Assets/cc_img7.png";
import iconimg8 from "../../Assets/cc_img8.png";
import iconimg10 from "../../Assets/cc_img10.png";
import Carousel from "react-bootstrap/Carousel";
const DigitalAgents = () => {
  const containerStyle = {
    background: `url(${backgroundImage}) center/cover no-repeat`,
  };
  return (
    <>
      <div>
        <div style={containerStyle}>
          <Container>
            <Row className="align-items-center pt-3 pb-3">
              <Col lg={6} md={6} sm={6} className="text-start">
                <img
                  src={Logoimage}
                  className="img-fluid"
                  style={{ width: "279px", height: "86px" }}
                  alt="logo"
                />
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: "34px",
                    fontWeight: 500,
                    lineHeight: "30px",
                  }}
                >
                  Business Problems Solved
                </p>
              </Col>
              <Col lg={6} md={6} sm={3} className="text-end logo_smnone">
                <img
                  src={Logoimg}
                  className="img-fluid"
                  style={{ width: "219px", height: "227px" }}
                  alt="logo"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="container-fluid d-lg-block d-none" style={{ backgroundColor: "#EEEEF3" }}>
          <Row className="pt-5 pb-5">
            <Col lg={12} md={12} sm={12}>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#02A6D9",
                }}
                className="title text-center"
              >
                The Digital Agent Framework
              </p>
              <h5
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#0F3659",
                }}
                className="title text-center"
              >
                How can digital agents help?
              </h5>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Carousel>
                <Carousel.Item>
                  <div className="carousel_bg">
                    <Carousel.Caption>
                      <p
                        className="mt-2 text-center"
                        style={{
                          fontSize: "16px",
                          color: "#737791",
                          textAlign: "justify",
                          fontWeight: "400",
                        }}
                      >
                        The virtual autonomous agents within a Treasury Digital
                        Twin are designed to address a variety of complex
                        challenges faced by
                        <br />
                        modern treasury departments. Example activities in
                        Treasury where digital agents could supplement the human
                        team…
                      </p>
                      <div className="d-flex justify-content-center">
                        <Row>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "15px",
                                  }}
                                >
                                  <img
                                    src={iconimg1}
                                    alt="icon"
                                    style={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                  Real-Time Data Synthesis
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Delays in financial reporting and lack of
                                  real-time visibility. <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  By continuously
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "15px",
                                  }}
                                >
                                  <img
                                    src={iconimg2}
                                    alt="icon"
                                    style={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                  Advance Risk Management
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Difficulty in assessing and mitigating
                                  financial risks due to market volatility and
                                  complex financial products. <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Agents simulate various market conditions to
                                  predict risk exposure, allowing for proactive
                                  risk management strategies.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  className="row"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "initial",
                                    lineHeight: "25px",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src={iconimg39}
                                      alt="icon"
                                      style={{
                                        marginRight: "10px",
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-9 d-flex justify-content-center">
                                    Efficient Cash and Liquidity <br />{" "}
                                    Forecasting
                                  </div>
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Challenges in forecasting cash flow &
                                  liquidity due to unpredictable financial
                                  conditions.
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Through predictive analytics, agents can
                                  forecast future cash positions, helping
                                  optimize liquidity and working capital
                                  management.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Row>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "15px",
                                  }}
                                >
                                  <img
                                    src={iconimg4}
                                    alt="icon"
                                    style={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                  Regulatory Compliance Monitoring
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Keeping up with and complying with constantly
                                  changing financial regulations. <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Agents track regulatory changes and assess
                                  their impact on treasury operations, ensuring
                                  continuous compliance.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  className="row"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "25px",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src={iconimg5}
                                      alt="icon"
                                      style={{
                                        marginRight: "10px",
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-9 d-flex justify-content-center">
                                    Integration of Disparate <br /> Financial
                                    Systems
                                  </div>
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Siloed information across various financial
                                  systems. <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Agents integrate and harmonize data from
                                  different systems, presenting a unified
                                  financial picture.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "15px",
                                  }}
                                >
                                  <img
                                    src={iconimg6}
                                    alt="icon"
                                    style={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                  Strategic Decision Support
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Making strategic decisions in the absence of
                                  comprehensive data analysis.
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  The collective intelligence of agents provides
                                  deep insights and scenario analysis,
                                  supporting informed strategic planning.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="carousel_bg">
                    <Carousel.Caption>
                      <p
                        className="mt-2 text-center"
                        style={{
                          fontSize: "16px",
                          color: "#737791",
                          textAlign: "justify",
                          fontWeight: "400",
                        }}
                      >
                        In essence, the virtual autonomous agents within a
                        Treasury Digital Twin are problem-solvers that automate,
                        predict,
                        <br />
                        and optimize treasury functions to reduce operational
                        risks, enhance
                      </p>
                      <div className="d-flex justify-content-center">
                        <Row>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  className="row"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "25px",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src={iconimg7}
                                      alt="icon"
                                      style={{
                                        marginRight: "10px",
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-9 d-flex justify-content-center">
                                    Optimization of Banking <br /> Relationships
                                  </div>
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Managing multiple banking relationships and
                                  optimizing bank account structures. <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Agents analyze transaction costs, bank fees,
                                  and interest to recommend optimal banking
                                  solutions.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "15px",
                                  }}
                                >
                                  <img
                                    src={iconimg8}
                                    alt="icon"
                                    style={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                  Enhancing Investment Decisions
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Identifying the best investment opportunities
                                  considering the risk-return profile. <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Investment-related agents assess market data
                                  to propose strategies that align with the
                                  organization’s investment policy.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  className="row"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "25px",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src={iconimg39}
                                      alt="icon"
                                      style={{
                                        marginRight: "10px",
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </div>
                                  <div className="col-9 d-flex justify-content-center">
                                    Automation of Routine <br /> Treasury Tasks
                                  </div>
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Time-consuming manual processes for routine
                                  treasury tasks.
                                  <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Agents automate standard tasks such as
                                  transaction processing and report generation,
                                  freeing staff for higher-value work.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          ></Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          >
                            <Card
                              style={{
                                padding: "10px",
                                margin: "10px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "400px",
                                height: "279px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    color: "#0F3659",
                                    textAlign: "justify",
                                    lineHeight: "15px",
                                  }}
                                >
                                  <img
                                    src={iconimg10}
                                    alt="icon"
                                    style={{
                                      marginRight: "10px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                  Audit and Reporting Efficiency
                                </Card.Title>
                                <Card.Text
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "23.8px",
                                    textAlign: "justify",
                                    color: "#737791",
                                    paddingTop: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    Problem:
                                  </span>{" "}
                                  Labor-intensive audit processes and financial
                                  reporting. <br />
                                  <span
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    How:
                                  </span>{" "}
                                  Agents assist in the audit by maintaining a
                                  clear, traceable record of all transactions
                                  and operations, and automate the generation of
                                  reports.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            lg={4}
                            md={12}
                            sm={12}
                            className="mt-3 d-flex justify-content-center"
                          ></Col>
                        </Row>
                      </div>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default DigitalAgents;
