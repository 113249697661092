import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../Header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import img from "../../Assets/logo_darkblue.png";
import user from "../../Assets/userpl.png";
import userpl from "../../Assets/demoimg.png";
import greenme from "../../Assets/Ellipse 1119.svg";
import greenDot from "../../Assets/green.png";
import yellowDot from "../../Assets/yellow.png";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Container fluid className="mt-4 mb-5 pb-5">
        <Row>
          <p style={{fontSize:'24px', fontWeight:600}}>Workforce</p>
          <span style={{fontSize:'18px', fontWeight:500, color:'#02A6D9'}}>Business Leads</span>
          <Col sm={12} md={6} lg={3} className="mt-3">
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
              <Card.Body className="text-center p-3">
                <Image
                  src={userpl}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={greenme}
                    className="img-fluid image_pos"
                    style={{  width: "20px", height: "20px"  }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white cardFooter">
                <small style={{fontSize:'18px', fontWeight:500}}>Me</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3">
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={user}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={greenme}
                    className="img-fluid image_pos"
                    style={{ width: "20px", height: "20px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white cardFooter">
                <small  style={{fontSize:'18px', fontWeight:500}}>Mandy Brown</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3">
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={greenme}
                    className="img-fluid image_pos"
                    style={{ width: "20px", height: "20px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white cardFooter">
                <small  style={{fontSize:'18px', fontWeight:500}}>John Smith</small>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4 mb-2">
          <span style={{fontSize:'18px', fontWeight:500, color:'#02A6D9'}}>Digital Workers</span>
          <Col sm={12} md={6} lg={3} className="mt-3" >
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
              <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={greenDot}
                    className="img-fluid image_posdown"
                    style={{ width: "22px", height: "22px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white" style={{backgroundColor:'#02A6D9'}}>
                <small  style={{fontSize:'18px', fontWeight:500}}>Market data agent</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3" >
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={yellowDot}
                    className="img-fluid image_posdown"
                    style={{ width: "22px", height: "22px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white" style={{backgroundColor:'#02A6D9'}}>
                <small  style={{fontSize:'18px', fontWeight:500}}>Analytics agent</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3" >
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={yellowDot}
                    className="img-fluid image_posdown"
                    style={{ width: "22px", height: "22px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white" style={{backgroundColor:'#02A6D9'}}>
                <small  style={{fontSize:'18px', fontWeight:500}}>Exposure collection</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3" >
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={greenDot}
                    className="img-fluid image_posdown"
                    style={{ width: "22px", height: "22px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white" style={{backgroundColor:'#02A6D9'}}>
                <small  style={{fontSize:'18px', fontWeight:500}}>Exposure insights</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3" >
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={yellowDot}
                    className="img-fluid image_posdown"
                    style={{ width: "22px", height: "22px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white" style={{backgroundColor:'#02A6D9'}}>
                <small  style={{fontSize:'18px', fontWeight:500}}>Risk balancer</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3" >
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />
                <span>
                  <img
                    src={yellowDot}
                    className="img-fluid image_posdown"
                    style={{ width: "22px", height: "22px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white" style={{backgroundColor:'#02A6D9'}}>
                <small  style={{fontSize:'18px', fontWeight:500}}>Dynamic hedger</small>
              </Card.Footer>
            </Card>
          </Col>
          <Col sm={12} md={6} lg={3} className="mt-3" >
            <Card
              style={{ width: "17rem" }}
              className="border-1 border border-light-subtle shadow"
            >
               <Card.Body className="text-center p-3">
                <Image
                  src={img}
                  alt="User"
                  className="img-fluid"
                  style={{ cursor: "pointer", height: "80px" }}
                />                
                <span>
                  <img
                    src={greenme}
                    className="img-fluid image_pos"
                    style={{  width: "20px", height: "20px"  }}
                    alt="dot"
                  />
                </span>
                <span>
                  <img
                    src={yellowDot}
                    className="img-fluid image_posdown"
                    style={{ width: "22px", height: "22px" }}
                    alt="dot"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="text-center text-white" style={{backgroundColor:'#02A6D9'}}>
                <small  style={{fontSize:'18px', fontWeight:500}}>Compliance monitor</small>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
