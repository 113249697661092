import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import collaboration from "../../Assets/collaboration.png";
import insight from "../../Assets/insight.png";
import transaction from "../../Assets/transaction.png";
const Overview = () => {
  const [selectedCard, setSelectedCard] = useState("Collaboration");

  const handleCardClick = (cardName) => {
    setSelectedCard(cardName);
  };

  const getCardStyle = (cardName) => ({
    padding: "10px",
    margin: "10px",
    borderRadius: "10px",
    borderColor: "#737791",
    background:
      selectedCard === cardName
        ? "linear-gradient(180deg, #00B7C5 20%, #0072C5 100%)"
        : "#FFFFFF",
    color: selectedCard === cardName ? "#FFFFFF" : "#0F3659",
  });

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center pt-5 container-fluid"
        style={{ backgroundColor: "#EEEEF3" }}
      >
        <div className="">
          <div
            className="row"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className="d-flex justify-content-center">
              <div className="col-lg-12">
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#02A6D9",
                  }}
                  className="title text-center"
                >
                  The Platform
                </p>
                <p
                  className="mt-2 text-center"
                  style={{
                    fontSize: "33px",
                    color: "#0F3659",
                    textAlign: "justify",
                    fontWeight: "500",
                  }}
                >
                  Overview
                </p>
              </div>
            </div>
          </div>
          {/* <div className='d-flex justify-content-center mt-3 col-lg-12'>
                        <img src={overview} alt="icon" style={{}} />
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <p style={{ fontSize: '23px', fontWeight: '400', color: '#737791' }} className="title text-center">A place for the treasury professional to connect with
                            other key stakeholders from consultants, to banks,<br />
                            auditors, peers, software vendors, etc.</p>
                    </div> */}
          <div
            className="d-flex justify-content-center mt-3"
            style={{ backgroundColor: "#EEEEF3", minHeight: "70vh" }}
          >
            {selectedCard === "Collaboration" && (
              <div>
                <div className="d-flex justify-content-center mt-3 col-lg-12">
                  <img src={collaboration} alt="icon" className="img-fluid" />
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <p
                    style={{
                      fontSize: "23px",
                      fontWeight: "400",
                      color: "#737791",
                    }}
                    className="title text-center"
                  >
                    A place for the treasury professional to connect with other
                    key stakeholders from consultants, to banks,
                    <br />
                    auditors, peers, software vendors, etc.
                  </p>
                </div>
              </div>
            )}
            {selectedCard === "Insights" && (
              <div>
                <div className="d-flex justify-content-center mt-3 col-lg-12">
                  <img src={insight} alt="icon" className="img-fluid" />
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <p
                    style={{
                      fontSize: "19px",
                      fontWeight: "400",
                      color: "#737791",
                    }}
                    className="title text-center"
                  >
                    We apply machine learning techniques to combine news items
                    with market data and treasury positions to deliver deep
                    insights<br></br>
                    and recommendations. Third party apps, market data
                    providers, news sites, event listings are brought together
                    into one place:<br></br>a central Treasury feed based on
                    your treasury digital twin.
                  </p>
                </div>
              </div>
            )}
            {selectedCard === "Transactions" && (
              <div>
                <div className="d-flex justify-content-center mt-3 col-lg-12">
                  <img src={transaction} alt="icon" className="img-fluid" />
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <p
                    style={{
                      fontSize: "19px",
                      fontWeight: "400",
                      color: "#737791",
                    }}
                    className="title text-center"
                  >
                    We facilitate unified transactions across multiple providers
                    from consultants, to banks, to brokers to auditors.<br></br>
                    Controlled workflows and third-party APIs are used to
                    transact with banks and providers.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ backgroundColor: "#EEEEF3" }}
      >
        <Row className="col-10">
          <Col lg={4} sm={12} className="mt-3">
            <Card
              style={getCardStyle("Collaboration")}
              onClick={() => handleCardClick("Collaboration")}
            >
              <Card.Body className="text-center">
                <Card.Title
                  style={{
                    fontSize: "25px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Collaboration
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={12} className="mt-3">
            <Card
              style={getCardStyle("Insights")}
              onClick={() => handleCardClick("Insights")}
            >
              <Card.Body className="text-center">
                <Card.Title
                  style={{
                    fontSize: "25px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Insights
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} sm={12} className="mt-3">
            <Card
              style={getCardStyle("Transactions")}
              onClick={() => handleCardClick("Transactions")}
            >
              <Card.Body className="text-center">
                <Card.Title
                  style={{
                    fontSize: "25px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Transactions
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Overview;
