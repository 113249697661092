import React from "react";

const Product = () => {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <h1>Product page</h1>
      </div>
    </>
  );
};

export default Product;
