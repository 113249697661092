import React from "react";
import { Col, Row } from 'react-bootstrap';
import Footerlogo from "../../Assets/footer.png"

const Footer = () => {
    return (

        <div className='d-flex flex-column container-fluid p-3' style={{ backgroundColor: '#000' ,}}>
            <div className="container">
                <Row className="">
                    <Col><img src={Footerlogo} alt="icon" style={{}} /></Col>
                    <Col className="text-white">Copyright © 2024. All Rights Reserved.</Col>
            
                </Row>
            </div>
        </div>


    );
};

export default Footer;