import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import agent1 from "../../Assets/agentone.png";
import agent2 from "../../Assets/agenttwo.png";
import agent3 from "../../Assets/agentthree.png";
import market1 from "../../Assets/marketone.png";
import market2 from "../../Assets/markettwo.png";
import market3 from "../../Assets/markethree.png";
import cardagent from "../../Assets/agentcardimg.png";
const AgentAction = () => {
  const [selectedCard, setSelectedCard] = useState("agent");
  const handleCardClick = (cardName) => {
    setSelectedCard(cardName);
  };
  const getCardStyle = (cardName) => ({
    padding: "3px",
    margin: "10px",
    borderRadius: "10px",
    borderColor: "#737791",
    cursor: "pointer",
    background:
      selectedCard === cardName
        ? "linear-gradient(180deg, #00B7C5 20%, #0072C5 100%)"
        : "#FFFFFF",
    color: selectedCard === cardName ? "#FFFFFF" : "#0F3659",
  });
  return (
    <>
      <div style={{ backgroundColor: "#EEEEF3" }} >
        <div className="d-flex flex-column justify-content-center align-items-center container-fluid">
          <div className="">
            <div
              className="d-flex justify-content-center "
            >
              {selectedCard === "agent" && (
                <div style={{minHeight: "60vh"}} className="d-flex align-items-center col-12 mt-2">
                  <Row className="d-flex justify-content-center align-items-center ">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#02A6D9",
                        textAlign: "center",
                      }}
                    >
                      An Example: Fusion Treasury Agents and TMS
                    </p>
                    <p
                      style={{
                        fontSize: "30px",
                        color: "#0F3659",
                        textAlign: "center",
                        lineHeight: "35px",
                        fontWeight: "500",
                      }}
                    >
                      Examples: Cash Management Simulator Agent
                    </p>
                    <Col
                      lg={6}
                      sm={12}
                      className="d-flex flex-column justify-content-center"
                    >
                      <p
                        style={{
                          fontSize: "22px",
                          fontWeight: "400",
                          color: "#02A6D9",
                          textAlign: "start",
                        }}
                      >
                        Agent Action within the Digital Twin
                      </p>
                      <div
                        style={{ borderBottom: "2px solid #E75A45" }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={agent1}
                          style={{ height: "102px", margin: "5px" }}
                          alt="title"
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            color: "#E75A45",
                            margin: "5px",
                          }}
                        >
                          Proactive Monitoring
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            color: "#737791",
                            margin: "5px",
                          }}
                          className="d-none d-md-block d-lg-block"
                        >
                          The Cash Management Simulator Agent detects a
                          simulated increase in sales volume, which suggests
                          higher incoming cash flows. It proactively models the
                          potential early repayment of debt to save on interest
                          costs, which is not something a traditional TMS would
                          propose without direct user input.
                        </p>
                      </div>
                      <div
                        style={{ borderBottom: "2px solid #F1A04F" }}
                        className="d-flex justify-content-center align-items-center mt-3"
                      >
                        <img
                          src={agent2}
                          style={{ height: "102px", margin: "5px" }}
                          alt="title"
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            color: "#F1A04F",
                            margin: "5px",
                          }}
                        >
                          Real time Connectivity
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            color: "#737791",
                            margin: "5px",
                          }}
                          className="d-none d-md-block d-lg-block"
                        >
                          Upon receiving new tax regulation data, the Agent
                          simulates the impact on cash reserves and adjusts the
                          tax provisioning strategies accordingly, whereas the
                          TMS would simply update the tax records based on
                          actual changes.
                        </p>
                      </div>
                      <div
                        style={{ borderBottom: "2px solid #61BDB8" }}
                        className="d-flex justify-content-center align-items-center mt-3"
                      >
                        <img
                          src={agent3}
                          style={{ height: "102px", margin: "5px" }}
                          alt="title"
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            color: "#61BDB8",
                            margin: "5px",
                          }}
                        >
                          Simulation and Role Play
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            color: "#737791",
                            margin: "5px",
                          }}
                          className="d-none d-md-block d-lg-block"
                        >
                          The Agent can role-play negotiations with banks for
                          better interest rates on deposits in the Digital Twin
                          environment, something beyond the functional scope of
                          a TMS, which just tracks the interest rates as they
                          are.
                        </p>
                      </div>
                    </Col>
                    <Col
                      lg={4}
                      sm={12}
                      className="d-flex justify-content-center mt-3"
                    >
                      <Card
                        className="sm_res shadow"
                        style={{ width: "31rem", borderRadius: "14px" }}
                      >
                        <Card.Img
                          variant="top"
                          src={cardagent}
                          className="img-fluid"
                        />
                        <Card.Body>
                          <Card.Text
                            style={{
                              color: "#737791",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "23px",
                            }}
                          >
                            The Digital Twin’s Cash Management Simulator Agent
                            provides a{" "}
                            <span
                              style={{
                                color: "#02A6D9",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              proactive, predictive, and dynamic environment{" "}
                            </span>
                            that complements the{" "}
                            <span
                              style={{
                                color: "#02A6D9",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              static, reactive, and transaction-focused
                              capabilities of a real-world TMS{" "}
                            </span>
                            or other financial systems.
                          </Card.Text>
                          <Card.Text
                            style={{
                              color: "#737791",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "23px",
                            }}
                          >
                            It effectively allows treasury departments to run
                            'what-if' scenarios and stress tests, enabling
                            strategic foresight and informed decision-making in
                            treasury management.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
              {selectedCard === "market" && (
                <div style={{minHeight: "60vh"}} className="d-flex align-items-center col-12 mt-2">
                  <Row className="d-flex justify-content-center align-items-center ">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#02A6D9",
                        textAlign: "center",
                      }}
                    >
                      An Example: Fusion Treasury Agents and TMS
                    </p>
                    <p
                      style={{
                        fontSize: "30px",
                        color: "#0F3659",
                        textAlign: "center",
                        lineHeight: "35px",
                        fontWeight: "500",
                      }}
                    >
                      Anticipating Impact of Market Volatility
                    </p>
                    <Col
                      lg={6}
                      sm={12}
                      className="d-flex flex-column justify-content-center"
                    >
                      <p
                        style={{
                          fontSize: "22px",
                          fontWeight: "400",
                          color: "#02A6D9",
                          textAlign: "start",
                        }}
                      >
                        Agent Action within the Digital Twin
                      </p>
                      <div
                        style={{ borderBottom: "2px solid #E75A45" }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={market1}
                          style={{ height: "102px", margin: "5px" }}
                          alt="title"
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            color: "#E75A45",
                            margin: "5px",
                          }}
                        >
                          Digital Twin Action
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            color: "#737791",
                            margin: "5px",
                          }}
                          className="d-none d-md-block d-lg-block"
                        >
                          Scans the virtual market environment for signs of
                          volatility that could impact cash flow, such as
                          fluctuating commodity prices or foreign exchange
                          rates. For instance, upon recognizing a pattern that
                          suggests a potential drop in currency value, the agent
                          simulates the treasury's exposure to this risk, taking
                          into account current hedges, currency balances, and
                          upcoming transactions that could be affected.
                        </p>
                      </div>
                      <div
                        style={{ borderBottom: "2px solid #F1A04F" }}
                        className="d-flex justify-content-center align-items-center mt-3"
                      >
                        <img
                          src={market2}
                          style={{ height: "102px", margin: "5px" }}
                          alt="title"
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            color: "#F1A04F",
                            margin: "5px",
                          }}
                        >
                          Outcome of Simulation
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            color: "#737791",
                            margin: "5px",
                          }}
                          className="d-none d-md-block d-lg-block"
                        >
                          The agent, after processing the data, anticipates a
                          significant foreign exchange loss if the currency
                          value drops as projected. It then automatically tests
                          various hedging strategies in the Digital Twin
                          environment, calculating potential costs and savings,
                          and determines the most cost-effective hedging
                          approach.
                        </p>
                      </div>
                      <div
                        style={{ borderBottom: "2px solid #61BDB8" }}
                        className="d-flex justify-content-center align-items-center mt-3"
                      >
                        <img
                          src={market3}
                          style={{ height: "102px", margin: "5px" }}
                          alt="title"
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 500,
                            lineHeight: "30px",
                            color: "#61BDB8",
                            margin: "5px",
                          }}
                        >
                          Proactive Adjustment
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                            color: "#737791",
                            margin: "5px",
                          }}
                          className="d-none d-md-block d-lg-block"
                        >
                          Based on the simulation, the agent proposes
                          adjustments to the existing hedging contracts and
                          suggests negotiating forward contracts or options to
                          mitigate the anticipated risk. It also recommends
                          timing for these actions to lock in the best rates,
                          all before any real-world market changes occur.
                        </p>
                      </div>
                      <span
                        style={{
                          fontSize: "16px",
                          lineHeight: "30px",
                          color: "#02A6D9",
                          textAlign: "justify",
                          fontStyle: "italic",
                          fontWeight: 400,
                          marginTop: "10px",
                        }}
                      >
                        Digital Twin's autonomous agents provide proactive,
                        strategic recommendations by simulating potential market
                        events & their impact on treasury operations, in
                        contrast to a traditional TMS which requires manual
                        intervention to manage and respond to such events. The
                        Digital Twin thus enables treasury to strategize &
                        prepare for market changes before they happen, offering
                        a significant advantage in risk management and
                        decision-making.
                      </span>
                    </Col>
                    <Col
                      lg={4}
                      sm={12}
                      className="d-flex flex-column align-items-end mt-3"
                    >
                      <Card
                        className="sm_res shadow mt-3"
                        style={{ width: "29rem", borderRadius: "14px" }}
                      >
                        <Card.Body>
                          <Card.Title
                            style={{
                              color: "#0F3659",
                              fontWeight: 500,
                              fontSize: "18px",
                              lineHeight: "28px",
                            }}
                          >
                            Real TMS Action
                          </Card.Title>
                          <Card.Text
                            style={{
                              color: "#737791",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "23px",
                            }}
                          >
                            The TMS monitors live data feeds for actual currency
                            rates and{" "}
                            <span
                              style={{
                                color: "#737791",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              triggers alerts{" "}
                            </span>
                            when rates move beyond pre-defined thresholds. The
                            system itself{" "}
                            <span
                              style={{
                                color: "#737791",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              does not predict market movements or simulate the
                              impact{" "}
                            </span>
                            of these changes.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card
                        className="sm_res shadow mt-3"
                        style={{ width: "29rem", borderRadius: "14px" }}
                      >
                        <Card.Body>
                          <Card.Title
                            style={{
                              color: "#0F3659",
                              fontWeight: 500,
                              fontSize: "18px",
                              lineHeight: "28px",
                            }}
                          >
                            Real TMS Action
                          </Card.Title>
                          <Card.Text
                            style={{
                              color: "#737791",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "23px",
                            }}
                          >
                            The TMS monitors live data feeds for actual currency
                            rates and{" "}
                            <span
                              style={{
                                color: "#737791",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              triggers alerts{" "}
                            </span>
                            when rates move beyond pre-defined thresholds. The
                            system itself{" "}
                            <span
                              style={{
                                color: "#737791",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              does not predict market movements or simulate the
                              impact{" "}
                            </span>
                            of these changes.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card
                        className="sm_res shadow mt-3"
                        style={{ width: "29rem", borderRadius: "14px" }}
                      >
                        <Card.Body>
                          <Card.Title
                            style={{
                              color: "#0F3659",
                              fontWeight: 500,
                              fontSize: "18px",
                              lineHeight: "28px",
                            }}
                          >
                            Real TMS Action
                          </Card.Title>
                          <Card.Text
                            style={{
                              color: "#737791",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "23px",
                            }}
                          >
                            The TMS monitors live data feeds for actual currency
                            rates and{" "}
                            <span
                              style={{
                                color: "#737791",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              triggers alerts{" "}
                            </span>
                            when rates move beyond pre-defined thresholds. The
                            system itself{" "}
                            <span
                              style={{
                                color: "#737791",
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "23px",
                              }}
                            >
                              does not predict market movements or simulate the
                              impact{" "}
                            </span>
                            of these changes.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-3 pt-4">
          <Row className="col-10 justify-content-center">
            <Col lg={4} sm={12} className="">
              <Card
                style={getCardStyle("agent")}
                onClick={() => handleCardClick("agent")}
              >
                <Card.Body className="text-center">
                  <Card.Title
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Examples: Cash Management <br />
                    Simulator Agent
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} sm={12} className="">
              <Card
                style={getCardStyle("market")}
                onClick={() => handleCardClick("market")}
              >
                <Card.Body className="text-center">
                  <Card.Title
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Anticipating Impact of
                    <br /> Market Volatility
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AgentAction;
